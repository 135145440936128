import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import { PaymentMethod, Field as PlexoField } from '../../../store/config/types';
import { FormSwitchField } from '../../forms/FormSwitchField';
import { FormTextField } from '../../forms/FormTextField';

interface PlexoFieldsProps {
  fields: PlexoField[] | undefined;
  paymentMethod: PaymentMethod | null;
  isRegisterStep?: boolean;
  firstPaymentMethod?: boolean;
}

function PlexoFields({ fields, paymentMethod, isRegisterStep, firstPaymentMethod }: PlexoFieldsProps) {
  const getPlexoFieldData = (type: number) => {
    switch (type) {
      case 2049:
        return {
          name: 'Código de comercio',
          value: 'commerceNumber',
        };

      case 2051:
        return {
          name: 'Número de terminal',
          value: 'terminalNumber',
        };

      case 270:
        return {
          name: 'Número de cuenta',
          value: 'accountNumber',
        };
      case 532:
        return {
          name: 'Transferencias entre bancos',
          value: 'crossBankTransfers',
        };
      case 2060:
        return {
          name: 'RUT',
          value: 'rut',
        };
      case 2057:
        return {
          name: 'MCC',
          value: 'merchantCategoryCode',
        };
      case 2065:
        return {
          name: 'Código de comercio',
          value: 'paymentFacilitatorCommerceId',
        };
      case 2067:
        return {
          name: 'Bancos disponibles',
          value: 'availableBanks',
        };
      default:
        return { name: '', value: '', setValue: null };
    }
  };

  return (
    <>
      {fields &&
        fields.map((f) => {
          const fieldData = getPlexoFieldData(f.fieldType);

          switch (fieldData.value) {
            case '':
              return null;

            case 'crossBankTransfers':
              return (
                <Grid key={f.fieldType} item xs={12}>
                  <Field
                    name={fieldData.value}
                    component={FormSwitchField}
                    label={fieldData.name}
                    disabled={!!paymentMethod?.fields?.crossBankTransfers}
                  />
                </Grid>
              );

            default:
              return (
                <Grid key={f.fieldType} item xs={12}>
                  <Field
                    name={fieldData.value}
                    component={FormTextField}
                    type="text"
                    placeholder={fieldData.name}
                    disabled={paymentMethod !== null}
                  />
                </Grid>
              );
          }
        })}
    </>
  );
}

export default PlexoFields;
