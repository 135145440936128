import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Loading } from '../../components/Loading';
import { ScreenTitle } from '../../components/ScreenTitle';
import { PaymentMethodForm } from '../../components/forms/PaymentMethodForm';
import { RootState } from '../../store/config/types';

function FirstPaymentMethod() {
  const navigate = useNavigate();
  const { auth, business, paymentMethods } = useSelector((state: RootState) => state);
  const hasBusinessCreated = auth.account?.business?.id || business.createdBusiness;

  useEffect(() => {
    const cookies = new Cookies();

    if (cookies.get('token')) {
      if (!hasBusinessCreated && auth.account) {
        navigate('/register?step=2');
      } else if (
        hasBusinessCreated &&
        auth.account?.business?.hasCommerces &&
        !paymentMethods.createPaymentMethodSuccess
      ) {
        navigate('/dashboard');
      }
    } else {
      navigate('/login');
    }
  }, [
    auth.account,
    auth.account?.business?.hasCommerces,
    paymentMethods.createPaymentMethodSuccess,
    hasBusinessCreated,
    navigate,
  ]);

  return (
    <>
      {paymentMethods.loadingIssuers ? (
        <Loading />
      ) : (
        <div className="screen-container profile">
          <ScreenTitle title="Agrega tus medios de pago" />
          <PaymentMethodForm
            handleSuccess={() => navigate('/dashboard')}
            paymentMethod={null}
            firstPaymentMethod
            isRegisterStep
          />
        </div>
      )}
    </>
  );
}

export default FirstPaymentMethod;
