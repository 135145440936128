import { Card, Divider, useMediaQuery } from '@material-ui/core';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { useState } from 'react';
import dummy from '../../../assets/dummy.png';
import { urlHelper } from '../../../helpers/urlHelper';
import { Business, LinkToPay } from '../../../store/config/types';
import styles from '../../../styles/_variables.module.scss';
import AccordionLinkDetails from './AccordionLinkDetails';
import LinkDetailContent from './LinkDetailContent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardLeft: {
      display: 'flex',
      flexDirection: 'column',
      width: '45%',
      padding: '2.5625rem',
      height: 'fit-content',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    image: {
      maxWidth: '150px',
      maxHeight: '150px',
      marginRight: '0.75rem',
    },
    divider: {
      height: '80%',
      margin: '0 10px',
    },
    businessData: {
      '& p': {
        color: styles.slateBlue,
        fontSize: '0.75rem',
        fontWeight: Number(styles.normalTextWeight),
        margin: 0,
      },
      '& h1': {
        color: styles.slateBlue,
        fontSize: '1.0625rem',
        fontWeight: Number(styles.semiBoldTextWeight),
        margin: '0.5625rem 0 0 0',
      },
    },
  }),
);

interface LinkDetailCardProps {
  linkToPay: LinkToPay;
  business: Business | null;
}

function LinkDetailCard({ business, linkToPay }: LinkDetailCardProps) {
  const classes = useStyles();
  const theme = useTheme();
  const [src, setSrc] = useState(urlHelper.buildImageUrl(linkToPay.businessId));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const onImageError = () => {
    setSrc(dummy);
  };

  return (
    <Card className={classes.cardLeft}>
      <div className={classes.header}>
        <img className={classes.image} src={src} onError={onImageError} alt="payment method logo" />
        <Divider orientation="vertical" className={classes.divider} flexItem />
        <div className={classes.businessData}>
          <p>Empresa emisora</p>
          <h1>{business?.prettyName}</h1>
        </div>
      </div>
      {isMobile ? (
        <AccordionLinkDetails linkToPay={linkToPay} />
      ) : (
        <LinkDetailContent linkToPay={linkToPay} />
      )}
    </Card>
  );
}

export default LinkDetailCard;
