import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import { PaymentMethod, Field as PlexoField } from '../../../store/config/types';
import { FormSwitchField } from '../../forms/FormSwitchField';
import { FormTextField } from '../../forms/FormTextField';

interface PlexoFieldsProps {
  fields: PlexoField[] | undefined;
  paymentMethod: PaymentMethod | null;
}

interface FieldType {
  value: string;
  name: string;
}

function SubBusinessPlexoFields({ fields, paymentMethod }: PlexoFieldsProps) {
  if (!fields) return null;

  const getPlexoFieldData = (type: number) => {
    switch (type) {
      case 2049:
        return {
          name: 'Código de comercio',
          value: 'commerceNumber',
        };

      case 2051:
        return {
          name: 'Número de terminal',
          value: 'terminalNumber',
        };

      case 270:
        return {
          name: 'Número de cuenta',
          value: 'accountNumber',
        };
      case 532:
        return {
          name: 'Transferencias entre bancos',
          value: 'crossBankTransfers',
        };
      case 2060:
        return {
          name: 'RUT',
          value: 'rut',
        };
      case 2057:
        return {
          name: 'MCC',
          value: 'merchantCategoryCode',
        };

      default:
        return { name: '', value: '', setValue: null };
    }
  };

  const renderCrossBankTransfersField = (fieldData: FieldType, paymentMethod: PaymentMethod) => (
    <Grid key={fieldData.value} item xs={12}>
      <Field
        name={fieldData.value}
        component={FormSwitchField}
        label={fieldData.name}
        disabled={!!paymentMethod?.fields?.crossBankTransfers}
      />
    </Grid>
  );

  const renderDefaultField = (fieldData: FieldType) => (
    <Grid key={fieldData.value} item xs={12}>
      <Field name={fieldData.value} component={FormTextField} type="text" placeholder={fieldData.name} />
    </Grid>
  );

  return (
    <>
      {fields.map((f) => {
        const fieldData = getPlexoFieldData(f.fieldType);

        if (fieldData.value === '') {
          return null;
        }

        if (fieldData.value === 'crossBankTransfers') {
          return renderCrossBankTransfersField(fieldData, paymentMethod!);
        }

        return renderDefaultField(fieldData);
      })}
    </>
  );
}

export default SubBusinessPlexoFields;
