import * as Sentry from '@sentry/react';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import signalRMiddleware from '../middlewares/signalRMiddleware';
import { authReducer } from '../reducers/auth';
import { businessReducer } from '../reducers/business';
import { clientsReducer } from '../reducers/clients';
import { collaboratorsReducer } from '../reducers/collaborators';
import { linksReducer } from '../reducers/links';
import { notificationsReducer } from '../reducers/notifications';
import { paymentMethodsReducer } from '../reducers/paymentMethods';
import { paymentsReducer } from '../reducers/payments';
import { subBusinessReducer } from '../reducers/subBusiness';
import { AppActions } from './ActionTypes';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const rootReducer = combineReducers({
  auth: authReducer,
  business: businessReducer,
  collaborators: collaboratorsReducer,
  paymentMethods: paymentMethodsReducer,
  links: linksReducer,
  payments: paymentsReducer,
  clients: clientsReducer,
  notifications: notificationsReducer,
  subBusiness: subBusinessReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>, signalRMiddleware),
    sentryReduxEnhancer,
  ),
);
