import { Dispatch } from 'redux';
import { subBusinessService } from '../../services/subBusiness.service';
import {
  ADD_SUB_BUSINESS,
  ADD_SUB_BUSINESS_PAYMENT_METHOD_FAILED,
  ADD_SUB_BUSINESS_PAYMENT_METHOD_SUCCESS,
  ADDING_SUB_BUSINESS_PAYMENT_METHOD,
  CREATE_SUB_BUSINESS_FAILED,
  CREATE_SUB_BUSINESS_SUCCESS,
  CreateSubBusinessTypes,
  CREATING_SUB_BUSINESS,
  DELETE_SUB_BUSINESS_PAYMENT_METHOD_FAILED,
  DELETE_SUB_BUSINESS_PAYMENT_METHOD_SUCCESS,
  DELETING_SUB_BUSINESS_PAYMENT_METHOD,
  FETCH_SUB_BUSINESS_FAILED,
  FETCHING_SUB_BUSINESS,
  FetchSubBusinessTypes,
  UPDATE_SUB_BUSINESS_FAILED,
  UPDATE_SUB_BUSINESS_SUCCESS,
  UpdateSubBusinessTypes,
  UPDATING_SUB_BUSINESS,
} from '../config/ActionTypes';
import {
  CreatePaymentMethodForSubBusinessRequest,
  CreateSubBusinessRequest,
  PaymentMethod,
  SubBusiness,
  UpdateSubBusinessRequest,
} from '../config/types';

export const fetchSubBusiness = (businessId: number) => {
  return (dispatch: Dispatch<FetchSubBusinessTypes>) => {
    dispatch(loadingSubBusiness());

    return subBusinessService.fetchSubBusiness(businessId).then(
      (response) => {
        dispatch(addSubBusiness(response!));
      },
      (error) => {
        dispatch(subBusinessFailed(error));
      },
    );
  };
};

export const loadingSubBusiness = (): FetchSubBusinessTypes => ({
  type: FETCHING_SUB_BUSINESS,
});

export const addSubBusiness = (subBusiness: SubBusiness[]): FetchSubBusinessTypes => ({
  type: ADD_SUB_BUSINESS,
  subBusiness,
});

export const subBusinessFailed = (error: string): FetchSubBusinessTypes => ({
  type: FETCH_SUB_BUSINESS_FAILED,
  error,
});

//#region Update SubBusiness

export const createSubBusiness = (createSubBusinessRequest: CreateSubBusinessRequest) => {
  return (dispatch: Dispatch<CreateSubBusinessTypes>) => {
    dispatch(creatingSubBusiness());

    return subBusinessService.createSubBusiness(createSubBusinessRequest).then(
      (response) => {
        dispatch(createSubBusinessSuccess(response!));
      },
      (error) => {
        dispatch(createSubBusinessFailed(error));
      },
    );
  };
};

export const creatingSubBusiness = (): CreateSubBusinessTypes => ({
  type: CREATING_SUB_BUSINESS,
});

export const createSubBusinessSuccess = (subBusiness: SubBusiness): CreateSubBusinessTypes => ({
  type: CREATE_SUB_BUSINESS_SUCCESS,
  subBusiness,
});

export const createSubBusinessFailed = (error: string): CreateSubBusinessTypes => ({
  type: CREATE_SUB_BUSINESS_FAILED,
  error,
});

//endregion

//#region Update SubBusiness

export const updateSubBusiness = (updateSubBusinessRequest: UpdateSubBusinessRequest) => {
  return (dispatch: Dispatch<UpdateSubBusinessTypes>) => {
    dispatch(updatingSubBusiness());

    return subBusinessService.updateSubBusiness(updateSubBusinessRequest).then(
      (response) => {
        dispatch(updateSubBusinessSuccess(response!));
      },
      (error) => {
        dispatch(updateSubBusinessFailed(error));
      },
    );
  };
};

export const updatingSubBusiness = (): UpdateSubBusinessTypes => ({
  type: UPDATING_SUB_BUSINESS,
});

export const updateSubBusinessSuccess = (subBusiness: SubBusiness): UpdateSubBusinessTypes => ({
  type: UPDATE_SUB_BUSINESS_SUCCESS,
  subBusiness,
});

export const updateSubBusinessFailed = (error: string): UpdateSubBusinessTypes => ({
  type: UPDATE_SUB_BUSINESS_FAILED,
  error,
});

export const resetUpdateSubBusinessPaymentMethodState = () => {
  return (dispatch: Dispatch<UpdateSubBusinessTypes>) => {
    dispatch({ type: 'RESET_UPDATE_SUB_BUSINESS_PAYMENT_METHOD_STATE' });
  };
};

//endregion

//#region Delete SubBusiness

export const deleteSubBusinessPaymentMethod = (
  businessId: number,
  paymentMethodId: number,
  subBusinessId: string,
) => {
  return (dispatch: Dispatch<UpdateSubBusinessTypes>) => {
    dispatch(deletingSubBusinessPaymentMethod());

    return subBusinessService.deleteSubBusinessPaymentMethod(businessId, paymentMethodId, subBusinessId).then(
      (response) => {
        dispatch(deleteSubBusinessPaymentMethodSuccess(response!));
      },
      (error) => {
        dispatch(deleteSubBusinessPaymentMethodFailed(error));
      },
    );
  };
};

export const deletingSubBusinessPaymentMethod = (): UpdateSubBusinessTypes => ({
  type: DELETING_SUB_BUSINESS_PAYMENT_METHOD,
});

export const deleteSubBusinessPaymentMethodSuccess = (subBusiness: SubBusiness): UpdateSubBusinessTypes => ({
  type: DELETE_SUB_BUSINESS_PAYMENT_METHOD_SUCCESS,
});

export const deleteSubBusinessPaymentMethodFailed = (error: string): UpdateSubBusinessTypes => ({
  type: DELETE_SUB_BUSINESS_PAYMENT_METHOD_FAILED,
  error,
});

//endregion

//#region Add PaymentMethod to SubBusiness

export const createPaymentMethodForSubBusiness = (
  createPaymentMethodForSubBusinessRequest: CreatePaymentMethodForSubBusinessRequest,
) => {
  return (dispatch: Dispatch<UpdateSubBusinessTypes>) => {
    dispatch(addingSubBusinessPaymentMethod());

    return subBusinessService
      .createPaymentMethodForSubBusiness(createPaymentMethodForSubBusinessRequest)
      .then(
        (response) => {
          dispatch(addSubBusinessPaymentMethodSuccess(response!));
        },
        (error) => {
          dispatch(addSubBusinessPaymentMethodFailed(error));
        },
      );
  };
};

export const addingSubBusinessPaymentMethod = (): UpdateSubBusinessTypes => ({
  type: ADDING_SUB_BUSINESS_PAYMENT_METHOD,
});

export const addSubBusinessPaymentMethodSuccess = (paymentMethod: PaymentMethod): UpdateSubBusinessTypes => ({
  type: ADD_SUB_BUSINESS_PAYMENT_METHOD_SUCCESS,
  paymentMethod,
});

export const addSubBusinessPaymentMethodFailed = (error: string): UpdateSubBusinessTypes => ({
  type: ADD_SUB_BUSINESS_PAYMENT_METHOD_FAILED,
  error,
});

//endregion
