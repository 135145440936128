import { Button, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import BoltMarginlessIcon from '../../assets/BoltMarginlessIcon';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontWeight: Number(styles.normalTextWeight),
      height: 'fit-content',
      marginRight: '1rem',
      '& .MuiButton-startIcon': {
        color: styles.white,
        marginRight: '0.5rem',
      },
      [theme.breakpoints.down('md')]: {
        margin: 0,
      },
    },
  }),
);

function OnTheFlyButton() {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Button
      disableElevation
      className={classes.button}
      color="secondary"
      variant="contained"
      startIcon={<BoltMarginlessIcon color={styles.white} />}
      onClick={() => navigate('/on-the-fly-payments')}
    >
      Pago rápido
    </Button>
  );
}

export default OnTheFlyButton;
