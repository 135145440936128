import { Grid, Paper, Typography } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ISSUERS_WITHOUT_INSTALLMENTS } from '../../../data/constants';
import schemas from '../../../data/schemas';
import { issuerHelper } from '../../../helpers/issuerHelper';
import {
  createPaymentMethod,
  fetchIssuers,
  updatePaymentMethod,
} from '../../../store/action_creators/paymentMethods.actions';
import { UserTypeEnum } from '../../../store/config/enums';
import {
  BasePaymentMethod,
  Client,
  CreatePaymentMethodRequest,
  Issuer,
  PaymentMethod,
  RootState,
  UpdatePaymentMethodRequest,
} from '../../../store/config/types';
import InstallmentsField from '../../dialogs/PaymentMethodDialog/InstallmentsField';
import PlexoFields from '../../dialogs/PaymentMethodDialog/PlexoFields';
import { Loading } from '../../Loading';
import IssuerAndAcquirer from './IssuerAndAcquirer';
import SnackbarManager from './SnackbarManager';
import SoftDescriptor from './SoftDescriptor';
import SubmitButton from './SubmitButton';

interface Values extends Omit<BasePaymentMethod, 'installments' | 'crossBankTransfers'> {
  issuer: string;
  acquirer: string;
  installments: number;
  crossBankTransfers: boolean;
  commerceNumber: string;
  terminalNumber: string;
  paymentFacilitatorCommerceId: string | undefined;
  availableBanks: string | undefined;
}

interface PaymentMethodFormProps {
  handleSuccess: () => void;
  paymentMethod: PaymentMethod | null;
  firstPaymentMethod?: boolean;
  client?: Client;
  isRegisterStep?: boolean;
  isDialog?: boolean;
}

function PaymentMethodForm({
  paymentMethod,
  handleSuccess,
  firstPaymentMethod = false,
  client,
  isRegisterStep = false,
  isDialog = false,
}: PaymentMethodFormProps) {
  const dispatch = useDispatch();
  const { auth, paymentMethods } = useSelector((state: RootState) => state);
  const [creatingPaymentMethod, setCreatingPaymentMethod] = useState<boolean>(false);
  const [updatingPaymentMethod, setUpdatingPaymentMethod] = useState<boolean>(false);
  const [selectedInstallments, setSelectedInstallments] = useState<number[]>(
    paymentMethod ? paymentMethod.values : [1],
  );

  useEffect(() => {
    if (!paymentMethods.issuers) {
      dispatch(fetchIssuers());
    }
  }, [paymentMethods.issuers, dispatch]);

  const isIssuerWithoutInstallments = (selectedIssuer: Issuer | null): boolean => {
    return selectedIssuer ? ISSUERS_WITHOUT_INSTALLMENTS.includes(selectedIssuer?.issuerId) : false;
  };

  const submitPaymentMethod = (values: Values, helpers: FormikHelpers<Values>) => {
    const crossBankTransfers = values.crossBankTransfers ? 'True' : 'False';

    if (auth.account) {
      const selectedIssuer =
        paymentMethods.issuers && paymentMethods.issuers.filter((i) => i.id === values.issuer)[0];
      const noInstallmentsIssuer = isIssuerWithoutInstallments(selectedIssuer);

      if (auth.account.type === UserTypeEnum.SUPERADMIN) {
        if (paymentMethod) {
          setUpdatingPaymentMethod(true);
          const updatePaymentMethodRequest: UpdatePaymentMethodRequest = {
            businessId: client!.id,
            issuerId: Number(paymentMethod.issuerId),
            installments: noInstallmentsIssuer ? [1] : selectedInstallments,
            maxInstallments: values.installments ?? 1,
            softDescriptor: values.softDescriptor?.trim(),
            acquirer: values.acquirer?.trim(),
            accountNumber: values.accountNumber?.trim(),
            crossBankTransfers,
            rut: values.rut?.trim(),
            merchantCategoryCode: values.merchantCategoryCode?.trim(),
          };
          dispatch(updatePaymentMethod(updatePaymentMethodRequest));
        } else {
          setCreatingPaymentMethod(true);

          const createPaymentMethodRequest: CreatePaymentMethodRequest = {
            businessId: client!.id,
            maxInstallments: values.installments ?? 1,
            installments: noInstallmentsIssuer ? [1] : selectedInstallments,
            softDescriptor: values.softDescriptor?.trim(),
            accountNumber: values.accountNumber?.trim(),
            commerceNumber: values.commerceNumber?.trim(),
            terminalNumber: values.terminalNumber?.trim(),
            issuerId: selectedIssuer!.issuerId,
            acquirer: selectedIssuer?.paymentProcessors ? values.acquirer?.trim() : null,
            crossBankTransfers,
            rut: values.rut?.trim(),
            merchantCategoryCode: values.merchantCategoryCode.trim(),
            paymentFacilitatorCommerceId: values.paymentFacilitatorCommerceId?.trim(),
            availableBanks: values.availableBanks ?? '',
          };
          dispatch(createPaymentMethod(createPaymentMethodRequest));
        }
      } else {
        if (paymentMethod) {
          setUpdatingPaymentMethod(true);

          const updatePaymentMethodRequest: UpdatePaymentMethodRequest = {
            businessId: auth.account.business.id!,
            issuerId: Number(paymentMethod.issuerId),
            maxInstallments: values.installments ?? 1,
            installments: noInstallmentsIssuer ? [1] : selectedInstallments,
            softDescriptor: values.softDescriptor?.trim() ?? '',
            accountNumber: values.accountNumber?.trim() ?? '',
            acquirer: values.acquirer?.trim() ?? '',
            crossBankTransfers,
            rut: values.rut?.trim() ?? '',
            merchantCategoryCode: values.merchantCategoryCode?.trim() ?? '',
          };

          dispatch(updatePaymentMethod(updatePaymentMethodRequest));
        } else {
          setCreatingPaymentMethod(true);

          const createPaymentMethodRequest: CreatePaymentMethodRequest = {
            businessId: auth.account.business.id!,
            maxInstallments: values.installments ?? 1,
            installments: noInstallmentsIssuer ? [1] : selectedInstallments,
            softDescriptor: values.softDescriptor?.trim(),
            accountNumber: values.accountNumber?.trim(),
            commerceNumber: values.commerceNumber?.trim(),
            terminalNumber: values.terminalNumber?.trim(),
            issuerId: selectedIssuer!.issuerId,
            acquirer: selectedIssuer?.paymentProcessors ? values.acquirer : null,
            crossBankTransfers,
            rut: values.rut?.trim(),
            merchantCategoryCode: values.merchantCategoryCode?.trim(),
            paymentFacilitatorCommerceId: values.paymentFacilitatorCommerceId?.trim(),
            availableBanks: values.availableBanks ?? '',
          };

          dispatch(createPaymentMethod(createPaymentMethodRequest));
        }
      }
    }
  };

  const closeSnack = () => {
    if (paymentMethod) {
      setUpdatingPaymentMethod(false);
    } else {
      setCreatingPaymentMethod(false);
    }

    if (paymentMethods.createPaymentMethodSuccess || paymentMethods.updatePaymentMethodSuccess) {
      handleSuccess();
    }
  };

  const issuersList = issuerHelper.getIssuersList(paymentMethods, paymentMethod);

  if (!paymentMethods.issuers) {
    return <Loading />;
  }

  const renderContent = (noInstallmentsIssuer: boolean, selectedIssuer: Issuer | undefined, fields: any) => (
    <>
      <Typography variant="h6" className={`${isDialog ? 'dialog ' : ''}form-title`}>
        {firstPaymentMethod || isRegisterStep
          ? 'Agrega un primer medio de pago'
          : paymentMethod
          ? 'Editar medio de pago'
          : 'Agregar nuevo medio de pago'}
      </Typography>
      {(isRegisterStep || firstPaymentMethod) && (
        <Typography className="first-payment-method-text">
          Antes de poder empezar a operar deberás agregar un primer medio de pago. ¿Aún no tienes códigos de
          comercio? <Link to="/request-issuers">Solicítalo aquí</Link>
        </Typography>
      )}
      <Grid container spacing={2}>
        <IssuerAndAcquirer
          paymentMethod={paymentMethod}
          installmentsField={
            !noInstallmentsIssuer && (
              <InstallmentsField
                paymentMethod={paymentMethod}
                selectedInstallments={selectedInstallments}
                setSelectedInstallments={setSelectedInstallments}
                selectedIssuer={selectedIssuer}
              />
            )
          }
          selectedIssuer={selectedIssuer!}
        />
        {!isRegisterStep && !firstPaymentMethod && !paymentMethod && (
          <Typography variant="subtitle1" component="p">
            ¿Necesitas un código de comercio?{' '}
            <Link to="/request-issuers">
              <u>Solicítalo aquí</u>
            </Link>
          </Typography>
        )}
        <SoftDescriptor paymentMethod={paymentMethod} />
        <PlexoFields
          fields={fields}
          paymentMethod={paymentMethod}
          isRegisterStep={isRegisterStep}
          firstPaymentMethod={firstPaymentMethod}
        />
      </Grid>
    </>
  );

  return (
    <Formik
      initialValues={{
        issuer:
          paymentMethod && paymentMethod.issuerId
            ? paymentMethod.issuerId.toString()
            : issuersList
            ? issuersList.filter((il) => !il.disabled)[0].id
            : '4',
        acquirer: (paymentMethod && paymentMethod.fields?.paymentProcessorId) || '',
        installments: paymentMethod ? Math.max(...paymentMethod.values) : 1,
        softDescriptor: (paymentMethod && paymentMethod.fields?.softDescriptor) || '',
        accountNumber: (paymentMethod && paymentMethod.fields?.accountNumber) || '',
        commerceNumber: (paymentMethod && paymentMethod.fields?.providerCommerceNumber) || '',
        terminalNumber: (paymentMethod && paymentMethod.fields?.terminalNumber) || '',
        crossBankTransfers:
          (paymentMethod && paymentMethod.fields?.crossBankTransfers === 'True' ? true : false) || false,
        paymentFacilitatorCommerceId: paymentMethod ? paymentMethod.fields?.paymentFacilitatorCommerceId : '',
        availableBanks: paymentMethod ? paymentMethod.fields?.availableBanks : '',
        rut: (paymentMethod && paymentMethod.fields?.rut) || '',
        merchantCategoryCode: (paymentMethod && paymentMethod.fields?.merchantCategoryCode) || '',
      }}
      validationSchema={schemas.PaymentMethodSchema}
      onSubmit={submitPaymentMethod}
      enableReinitialize={true}
    >
      {({ values }) => {
        const selectedIssuer =
          paymentMethods.issuers && paymentMethods.issuers.filter((i) => i.id === values.issuer)[0];

        const selectedAcquirer = selectedIssuer?.paymentProcessors
          ? selectedIssuer?.paymentProcessors.filter((pp) => pp.acquirer === values.acquirer)[0]
          : '';

        const fields = selectedAcquirer
          ? selectedAcquirer.fields
          : !selectedIssuer || !selectedIssuer.paymentProcessors
          ? selectedIssuer?.fields
          : paymentMethod
          ? selectedIssuer?.fields
          : [];

        const noInstallmentsIssuer = isIssuerWithoutInstallments(selectedIssuer);

        const isSubmitButtonDisabled = (!paymentMethod && !values.softDescriptor) || !values.acquirer;

        return (
          <Form className="form payment-method-form" id="payment-method-form">
            {isRegisterStep ? (
              <Paper elevation={2} className="paper-container">
                {renderContent(noInstallmentsIssuer, selectedIssuer!, fields)}
              </Paper>
            ) : (
              renderContent(noInstallmentsIssuer, selectedIssuer!, fields)
            )}
            <SubmitButton
              isRegisterStep={isRegisterStep}
              creatingPaymentMethod={creatingPaymentMethod}
              updatingPaymentMethod={updatingPaymentMethod}
              paymentMethod={paymentMethod}
              disabled={isSubmitButtonDisabled}
            />
            <SnackbarManager
              creatingPaymentMethod={creatingPaymentMethod}
              updatingPaymentMethod={updatingPaymentMethod}
              handleClose={closeSnack}
              firstPaymentMethod={firstPaymentMethod}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default PaymentMethodForm;
