import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import SubBusinessDialog from '../../components/dialogs/PaymentMethodDialog/SubBusinessDialog';
import { Loading } from '../../components/Loading';
import PaymentMethodsToAdd from '../../components/PaymentMethods/PaymentMethodsToAdd';
import { ScreenTitle } from '../../components/ScreenTitle';
import { useFetchLinksOnUpdate } from '../../hooks/useFetchLinksOnUpdate';
import { fetchPaymentMethods } from '../../store/action_creators/paymentMethods.actions';
import {
  createSubBusiness,
  fetchSubBusiness,
  updateSubBusiness,
} from '../../store/action_creators/subBusiness.actions';
import { Status, SubBusinessStatusEnum } from '../../store/config/enums';
import {
  CreateSubBusinessRequest,
  IssuersCommand,
  PaymentMethod,
  PaymentMethodToAdd,
  RootState,
  SubBusiness as SubBusinessType,
  UpdateSubBusinessRequest,
} from '../../store/config/types';
import DisableSubBusinessDialog from './DisableSubBusinessDialog';
import PaymentMethodSnackbar from './PaymentMethodSnackbar';
import SubBusinessActions from './SubBusinessActions';
import SubBusinessForm from './SubBusinessForm';

interface SubBusinessValues {
  name: string;
}

function SubBusiness() {
  const { auth, subBusiness, paymentMethods } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const selectedSubBusiness: SubBusinessType | undefined = subBusiness.subBusinesses?.find(
    (subBusiness) => subBusiness.id === params.subBusinessId,
  );
  const [open, setOpen] = useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null);
  const [creatingSubBusiness, setCreatingSubBusiness] = useState<boolean>(false);
  const [updatingSubBusiness, setUpdatingSubBusiness] = useState<boolean>(false);
  const [changingSubBusinessStatus, setChangingSubBusinessStatus] = useState<boolean>(false);
  const [paymentMethodsToAdd, setPaymentMethodsToAdd] = useState<IssuersCommand[]>([]);
  const [updatingSubBusinessPaymentMethod, setUpdatingSubBusinessPaymentMethod] = useState<boolean>(false);
  const [diableDialogOpen, setDisableDialogOpen] = useState<boolean>(false);
  const fetchLinksOnUpdate = useFetchLinksOnUpdate();
  const businessId = auth.account?.business.id;
  const subBusinessEnabled = selectedSubBusiness?.status === SubBusinessStatusEnum.ACTIVE;

  useEffect(() => {
    if (
      auth.account &&
      !paymentMethods.loadingPaymentMethods &&
      paymentMethods.paymentMethods === null &&
      paymentMethods.paymentMethodsErrorMessage === null
    ) {
      dispatch(fetchPaymentMethods(auth.account.business.id!));
    }
  }, [
    auth.account,
    paymentMethods.loadingPaymentMethods,
    paymentMethods.paymentMethods,
    paymentMethods.paymentMethodsErrorMessage,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(fetchSubBusiness(auth.account?.business.id!));
  }, [auth.account?.business.id, dispatch]);

  useEffect(() => {
    if (selectedSubBusiness) {
      const commercesOfSubBusiness = selectedSubBusiness.installmentsDto.map((paymentMethod) => {
        return {
          issuerId: paymentMethod.issuerId.toString(),
          installments: paymentMethod.values,
        };
      });
      setPaymentMethodsToAdd((prevPaymentMethodsToAdd) => [
        ...prevPaymentMethodsToAdd,
        ...commercesOfSubBusiness,
      ]);
    }
  }, [selectedSubBusiness]);

  const openModalWithCommerceData = (paymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    setOpen(true);
  };

  const submitSubBusiness = (values: SubBusinessValues) => {
    if (auth.account) {
      const configureIssuersCommand = paymentMethodsToAdd.map((commerce: IssuersCommand) => {
        return {
          softDescriptor: commerce.softDescriptor ?? '',
          commerceNumber: commerce.commerceNumber ?? '',
          terminalNumber: commerce.terminalNumber ?? '',
          issuerId: commerce.issuerId,
          installments: commerce.installments,
          acquirer: commerce.acquirer,
        };
      });

      if (!selectedSubBusiness) {
        setCreatingSubBusiness(true);

        const createSubBusinessRequest: CreateSubBusinessRequest = {
          name: values.name,
          businessId: businessId!,
          configureIssuersCommand,
        };

        dispatch(createSubBusiness(createSubBusinessRequest));
      } else {
        setUpdatingSubBusiness(true);

        const updateSubBusinessRequest: UpdateSubBusinessRequest = {
          subBusinessId: selectedSubBusiness.id,
          name: values.name,
        };

        dispatch(updateSubBusiness(updateSubBusinessRequest));
      }
    }
  };

  const addPaymentMethodToSubBusiness = (paymentMethod: PaymentMethodToAdd, isAlreadyInState: boolean) => {
    const issuerCommand: IssuersCommand = {
      issuerId: paymentMethod.issuer,
      softDescriptor: paymentMethod.softDescriptor,
      commerceNumber: paymentMethod.commerceNumber,
      terminalNumber: paymentMethod.terminalNumber,
      installments: paymentMethod.installments,
      acquirer: paymentMethod.acquirer,
    };

    if (isAlreadyInState) {
      setPaymentMethodsToAdd(
        paymentMethodsToAdd.filter((item: IssuersCommand) => +item.issuerId !== +issuerCommand.issuerId),
      );
    } else {
      setPaymentMethodsToAdd([...paymentMethodsToAdd, issuerCommand]);
    }

    if (selectedSubBusiness) {
      setUpdatingSubBusinessPaymentMethod(true);
    }
  };

  const changeSubBusinessStatus = () => {
    if (!selectedSubBusiness) return;

    setChangingSubBusinessStatus(true);

    const updateSubBusinessRequest: UpdateSubBusinessRequest = {
      subBusinessId: selectedSubBusiness.id,
      name: selectedSubBusiness.name,
      status: subBusinessEnabled ? SubBusinessStatusEnum.INACTIVE : SubBusinessStatusEnum.ACTIVE,
    };

    dispatch(updateSubBusiness(updateSubBusinessRequest));
  };

  const closeSnack = () => {
    setCreatingSubBusiness(false);
    setUpdatingSubBusiness(false);
    setChangingSubBusinessStatus(false);
    setDisableDialogOpen(false);
    fetchLinksOnUpdate();
    navigate(-1);
  };

  if (subBusiness.loadingSubBusiness) {
    return <Loading />;
  }

  return (
    <>
      <Formik
        initialValues={{
          name: selectedSubBusiness?.name || '',
        }}
        onSubmit={submitSubBusiness}
      >
        {(values) => {
          return (
            <div className="screen-container">
              <SubBusinessActions
                selectedSubBusiness={selectedSubBusiness}
                setDisableDialogOpen={setDisableDialogOpen}
                changeSubBusinessStatus={changeSubBusinessStatus}
                changingSubBusinessStatus={changingSubBusinessStatus}
                subBusinessEnabled={subBusinessEnabled}
                creatingSubBusiness={creatingSubBusiness}
                updatingSubBusiness={updatingSubBusiness}
                paymentMethodsToAdd={paymentMethodsToAdd}
                values={values}
              />
              <SubBusinessForm
                selectedSubBusiness={selectedSubBusiness}
                updatingSubBusiness={updatingSubBusiness}
                creatingSubBusiness={creatingSubBusiness}
              />
              <ScreenTitle title="Medios de Pago" />
              {updatingSubBusinessPaymentMethod ? (
                <Loading />
              ) : (
                <PaymentMethodsToAdd
                  selectedSubBusiness={selectedSubBusiness}
                  openModalWithCommerceData={openModalWithCommerceData}
                  paymentMethodsToAdd={paymentMethodsToAdd}
                  setUpdatingSubBusinessPaymentMethod={setUpdatingSubBusinessPaymentMethod}
                  addPaymentMethodToSubBusiness={addPaymentMethodToSubBusiness}
                />
              )}
              <SubBusinessDialog
                selectedSubBusiness={selectedSubBusiness}
                open={open}
                setOpen={setOpen}
                paymentMethod={selectedPaymentMethod}
                addPaymentMethodToSubBusiness={addPaymentMethodToSubBusiness}
              />
              <CustomSnackbar
                open={
                  (updatingSubBusiness || changingSubBusinessStatus) &&
                  (subBusiness.updateSubBusinessSuccess || subBusiness.updateSubBusinessErrorMessage !== null)
                }
                message={
                  subBusiness.updateSubBusinessSuccess
                    ? 'Comercio actualizado correctamente'
                    : 'Ocurrió un error al actualizar el comercio'
                }
                handleClose={closeSnack}
                type={subBusiness.updateSubBusinessSuccess ? Status.SUCCESS : Status.ERROR}
              />
              <CustomSnackbar
                open={
                  creatingSubBusiness &&
                  (subBusiness.createSubBusinessSuccess || subBusiness.createSubBusinessErrorMessage !== null)
                }
                message={
                  subBusiness.createSubBusinessSuccess
                    ? 'Comercio creado correctamente'
                    : 'Ocurrió un error al crear el comercio'
                }
                handleClose={closeSnack}
                type={subBusiness.createSubBusinessSuccess ? Status.SUCCESS : Status.ERROR}
              />
              <PaymentMethodSnackbar
                updatingSubBusinessPaymentMethod={updatingSubBusinessPaymentMethod}
                setUpdatingSubBusinessPaymentMethod={setUpdatingSubBusinessPaymentMethod}
              />
            </div>
          );
        }}
      </Formik>
      <DisableSubBusinessDialog
        open={diableDialogOpen}
        handleClose={() => setDisableDialogOpen(false)}
        handleConfirm={changeSubBusinessStatus}
        disabledButton={changingSubBusinessStatus}
      />
    </>
  );
}

export default SubBusiness;
