import {
  ADD_DASHBOARD_LINKS,
  ADD_LINKS,
  ADD_ONE_USE_LINKS,
  CREATE_LINK_FAILED,
  CREATE_LINK_SUCCESS,
  CREATING_LINK,
  DELETE_LINK_FAILED,
  DELETE_LINK_SUCCESS,
  DELETING_LINK,
  FETCHING_LINKS,
  FETCHING_ONE_USE_LINKS,
  FETCH_LINKS_FAILED,
  FETCH_ONE_USE_LINKS_FAILED,
  GETTING_LINK_DATA,
  GET_LINK_DATA_FAILED,
  GET_LINK_DATA_SUCCESS,
  LOAD_LINK_FILTERS,
  LOAD_ONE_USE_LINK_FILTERS,
  LOGOUT,
  LinksDataTypes,
  LogoutActionTypes,
  SHARE_LINK_FAILED,
  SHARE_LINK_SUCCESS,
  SHARING_LINK,
  UPDATE_LINK_FAILED,
  UPDATE_LINK_SUCCESS,
  UPDATING_LINK,
  VALIDATE_LINK_FAILED,
  VALIDATE_LINK_SUCCESS,
  VALIDATING_LINK,
} from '../config/ActionTypes';
import { DateSpan, LinkTypeEnum, OrderFilterEnum } from '../config/enums';
import { Link, LinksState } from '../config/types';

export const initialState: LinksState = {
  loadingLinks: false,
  loadingOneUseLinks: false,
  links: null,
  dashboardLinks: null,
  oneUseLinks: null,
  currentPage: 0,
  pageCount: 0,
  oneUseCurrentPage: 0,
  oneUsePageCount: 0,
  linksType: LinkTypeEnum.ONTHEFLY,
  linksFilters: {
    currency: { pesos: false, dollars: false },
    order: OrderFilterEnum.DescendingDate,
    dateSpan: DateSpan.LAST7DAYS,
    term: '',
    sortDirection: 2,
    columnSort: 1,
  },
  oneUseLinksFilters: {
    currency: { pesos: false, dollars: false },
    order: OrderFilterEnum.DescendingDate,
    dateSpan: DateSpan.LAST7DAYS,
    term: '',
    sortDirection: 2,
    columnSort: 1,
  },
  linksErrorMessage: null,
  oneUseLinksErrorMessage: null,
  creatingLink: false,
  createLinkSuccess: false,
  latestLink: null,
  createLinkErrorMessage: null,
  updatingLink: false,
  updateLinkSuccess: false,
  updateLinkErrorMessage: null,
  deletingLink: false,
  deleteLinkSuccess: false,
  deleteLinkErrorMessage: null,
  sharingLink: false,
  shareLinkSuccess: false,
  shareLinkErrorMessage: null,
  gettingLinkData: false,
  getLinkDataSuccess: false,
  linkData: null,
  getLinkDataErrorMessage: null,
  validatingLink: false,
  validateLinkSuccess: false,
  linkToPay: null,
  validateLinkErrorMessage: null,
};

export function linksReducer(state = initialState, action: LinksDataTypes | LogoutActionTypes): LinksState {
  switch (action.type) {
    case LOAD_LINK_FILTERS:
      return {
        ...state,
        linksFilters: action.filters,
        loadingLinks: true,
        links: null,
      };

    case LOAD_ONE_USE_LINK_FILTERS:
      return {
        ...state,
        oneUseLinksFilters: action.filters,
        loadingOneUseLinks: true,
        oneUseLinks: null,
      };

    case FETCHING_LINKS:
      const leftoverLinks = action.reset ? null : state.links;

      return {
        ...state,
        loadingLinks: true,
        links: leftoverLinks,
        linksType: action.kind,
        linksErrorMessage: null,
      };

    case ADD_LINKS:
      let linksToAdd = state.links;

      if (linksToAdd && action.links) {
        linksToAdd = linksToAdd.concat(action.links.results);
      } else {
        linksToAdd = action.links ? action.links.results : [];
      }
      return {
        ...state,
        loadingLinks: false,
        links: linksToAdd,
        currentPage: action.links ? action.links.currentPage : state.currentPage,
        pageCount: action.links ? action.links.pageCount : state.pageCount,
        linksErrorMessage: null,
      };

    case ADD_DASHBOARD_LINKS:
      return {
        ...state,
        loadingLinks: false,
        dashboardLinks: action.links ? action.links.results : [],
        linksErrorMessage: null,
      };

    case FETCH_LINKS_FAILED:
      return {
        ...state,
        loadingLinks: false,
        // links: null,
        linksErrorMessage: action.error,
      };

    //
    case FETCHING_ONE_USE_LINKS:
      const leftoverOneUseLinks = action.reset ? null : state.oneUseLinks;

      return {
        ...state,
        loadingOneUseLinks: true,
        oneUseLinks: leftoverOneUseLinks,
        linksType: action.kind,
        oneUseLinksErrorMessage: null,
      };

    case ADD_ONE_USE_LINKS:
      let oneUSeLinksToAdd = state.oneUseLinks;

      if (oneUSeLinksToAdd && action.links) {
        oneUSeLinksToAdd = oneUSeLinksToAdd.concat(action.links.results);
      } else {
        oneUSeLinksToAdd = action.links ? action.links.results : [];
      }
      return {
        ...state,
        loadingOneUseLinks: false,
        oneUseLinks: oneUSeLinksToAdd,
        oneUseCurrentPage: action.links ? action.links.currentPage : state.oneUseCurrentPage,
        oneUsePageCount: action.links ? action.links.pageCount : state.oneUsePageCount,
        oneUseLinksErrorMessage: null,
      };

    case FETCH_ONE_USE_LINKS_FAILED:
      return {
        ...state,
        loadingOneUseLinks: false,
        // links: null,
        oneUseLinksErrorMessage: action.error,
      };
    //
    case CREATING_LINK:
      return {
        ...state,
        creatingLink: true,
        createLinkSuccess: false,
        createLinkErrorMessage: null,
      };

    case CREATE_LINK_SUCCESS:
      const newLinks = state.links ? [...state.links] : [];
      const newOneUseLinks = state.oneUseLinks ? [...state.oneUseLinks] : [];
      const dashboardLinks = state.dashboardLinks ? [...state.dashboardLinks] : [];

      if (action.link.linkType === LinkTypeEnum.PERMANENT) {
        newLinks.unshift(action.link);
      } else {
        newOneUseLinks.unshift(action.link);
      }

      dashboardLinks.unshift(action.link);

      return {
        ...state,
        links: newLinks,
        oneUseLinks: newOneUseLinks,
        creatingLink: false,
        dashboardLinks: dashboardLinks,
        latestLink: action.link,
        createLinkSuccess: true,
        createLinkErrorMessage: null,
      };

    case CREATE_LINK_FAILED:
      return {
        ...state,
        creatingLink: false,
        createLinkSuccess: false,
        latestLink: null,
        createLinkErrorMessage: action.error,
      };

    case UPDATING_LINK:
      return {
        ...state,
        updatingLink: true,
        updateLinkSuccess: false,
        updateLinkErrorMessage: null,
      };

    case UPDATE_LINK_SUCCESS:
      let linksToUpdate = action.link.linkType === LinkTypeEnum.PERMANENT ? state.links : state.oneUseLinks;

      const updatedLinks = linksToUpdate?.map((link: Link) => {
        if (link.id === action.link.id) {
          const updatedLink: Link = {
            ...action.link,
          };
          if (action.link.description) updatedLink.description = action.link.description;
          if (action.link.reference) updatedLink.reference = action.link.reference;
          return updatedLink;
        }
        return link;
      });
      return {
        ...state,
        links: action.link.linkType === LinkTypeEnum.PERMANENT ? updatedLinks! : state.links,
        oneUseLinks: action.link.linkType === LinkTypeEnum.PERMANENT ? state.oneUseLinks : updatedLinks!,
        dashboardLinks:
          state.dashboardLinks &&
          state.dashboardLinks.map((link) =>
            link.id === action.link.id ? { ...link, ...action.link } : link,
          ),
        latestLink: action.link,
        updatingLink: false,
        updateLinkSuccess: true,
        updateLinkErrorMessage: null,
      };

    case UPDATE_LINK_FAILED:
      return {
        ...state,
        updatingLink: false,
        updateLinkSuccess: false,
        updateLinkErrorMessage: action.error,
      };

    case DELETING_LINK:
      return {
        ...state,
        deletingLink: true,
        deleteLinkSuccess: false,
        deleteLinkErrorMessage: null,
      };

    case DELETE_LINK_SUCCESS:
      const survivingLinks = state.links?.filter((link: Link) => link.id !== action.linkId);
      const survivingOneUseLinks = state.oneUseLinks?.filter((link: Link) => link.id !== action.linkId);
      const survivingDashboardLinks = state.dashboardLinks?.filter((link: Link) => link.id !== action.linkId);

      return {
        ...state,
        links: survivingLinks ? survivingLinks : null,
        oneUseLinks: survivingOneUseLinks ? survivingOneUseLinks : null,
        dashboardLinks: survivingDashboardLinks ? survivingDashboardLinks : null,
        deletingLink: false,
        deleteLinkSuccess: true,
        deleteLinkErrorMessage: null,
      };

    case DELETE_LINK_FAILED:
      return {
        ...state,
        deletingLink: false,
        deleteLinkSuccess: false,
        deleteLinkErrorMessage: action.error,
      };

    case SHARING_LINK:
      return {
        ...state,
        sharingLink: true,
        shareLinkSuccess: false,
        shareLinkErrorMessage: null,
      };

    case SHARE_LINK_SUCCESS:
      return {
        ...state,
        sharingLink: false,
        shareLinkSuccess: true,
        shareLinkErrorMessage: null,
      };

    case SHARE_LINK_FAILED:
      return {
        ...state,
        sharingLink: false,
        shareLinkSuccess: false,
        shareLinkErrorMessage: action.error,
      };

    case GETTING_LINK_DATA:
      return {
        ...state,
        gettingLinkData: true,
        getLinkDataSuccess: false,
        linkData: null,
        getLinkDataErrorMessage: null,
      };

    case GET_LINK_DATA_SUCCESS:
      return {
        ...state,
        gettingLinkData: false,
        getLinkDataSuccess: true,
        linkData: action.link,
        getLinkDataErrorMessage: null,
      };

    case GET_LINK_DATA_FAILED:
      return {
        ...state,
        gettingLinkData: false,
        getLinkDataSuccess: false,
        linkData: null,
        getLinkDataErrorMessage: action.error,
      };

    case VALIDATING_LINK: {
      return {
        ...state,
        validatingLink: true,
        validateLinkSuccess: false,
        linkToPay: null,
        validateLinkErrorMessage: null,
      };
    }

    case VALIDATE_LINK_SUCCESS: {
      return {
        ...state,
        validatingLink: false,
        validateLinkSuccess: true,
        linkToPay: action.link,
        validateLinkErrorMessage: null,
      };
    }

    case VALIDATE_LINK_FAILED: {
      return {
        ...state,
        validatingLink: false,
        validateLinkSuccess: false,
        linkToPay: null,
        validateLinkErrorMessage: action.error,
      };
    }

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
