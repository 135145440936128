import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Field } from 'formik';
import { Link } from '../../../../store/config/types';
import { FormSelectField } from '../../../forms/FormSelectField';
import { FormTextField } from '../../../forms/FormTextField';
import { StatusPill } from '../../../StatusPill';
import options from '../../inputData';
import VATtooltip from '../../VATTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    informationCol: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      '& p': {
        margin: '0.8rem 0 -0.6rem 0 ',
      },
    },
    informationRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: '0.9375rem',
      flexWrap: 'nowrap',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    tooltipContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0.8rem 0 -0.6rem 0 ',
      '& span': {
        display: 'flex',
        marginLeft: '0.375rem',
      },
      '& p': {
        margin: 0,
      },
    },
    flex: {
      flex: 1,
    },
    flexTwo: {
      flex: 2,
    },
  }),
);

interface SplitLinkPaymentInputProps {
  valueId: string;
  availableSelectOptions: { id: string; value: string }[];
  link?: Link;
  isBusiness?: boolean;
}

function SplitLinkPaymentInput({
  valueId,
  availableSelectOptions,
  link,
  isBusiness,
}: SplitLinkPaymentInputProps) {
  const classes = useStyles();

  return (
    <>
      <StatusPill isCard label={`Destino ${isBusiness ? '1' : '2'}`} color="info" />
      <div className={classes.informationRow}>
        <div className={`${classes.informationCol} ${classes.flexTwo}`}>
          <p>Empresa</p>
          <Field
            name={isBusiness ? 'businessId' : 'subBusinessId'}
            component={FormSelectField}
            type="text"
            options={availableSelectOptions}
            value={valueId}
            disabled={isBusiness || !!link}
          />
        </div>
        <div className={`${classes.informationCol} ${classes.flex}`}>
          <>
            <p>Importe</p>
            <Field
              name={isBusiness ? 'amount' : 'subBusinessAmount'}
              component={FormTextField}
              type="tel"
              noLeadingZeros
            />
          </>
        </div>
        <div className={`${classes.informationCol} ${classes.flex}`}>
          <div className={classes.tooltipContainer}>
            <p>IVA</p>
            <VATtooltip title="El IVA seleccionado se aplicará únicamente para este link de pago." />
          </div>
          <Field
            name={isBusiness ? 'vatRate' : 'subBusinessVatRate'}
            component={FormSelectField}
            type="text"
            options={options.vatRate}
          />
        </div>
      </div>
    </>
  );
}

export default SplitLinkPaymentInput;
