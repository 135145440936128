import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core';
import colors from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '1.5625rem',
      [theme.breakpoints.down('sm')]: {
        '& .MuiDialog-paper': {
          margin: '0',
          width: '100%',
        },
      },
      '& .MuiDialogActions-root': {
        justifyContent: 'center',
        paddingBottom: '1.5625rem',
      },
    },
    title: {
      padding: '1.5625rem 1.875rem 0.3125rem',
      textAlign: 'center',
    },
    contentText: {
      textAlign: 'center',
      fontSize: '0.9375rem',
      color: colors.slateBlue,
    },
    deleteButton: {
      backgroundColor: colors.primary,
      border: `1px solid ${colors.primary}`,
      color: colors.white,
      '&:hover': {
        backgroundColor: colors.white,
        color: colors.primary,
        boxShadow: 'none',
      },
      '&.Mui-disabled': {
        backgroundColor: colors.primary,
        border: 'none',
        color: colors.white,
        opacity: 0.5,
        minHeight: '2.8rem',
      },
    },
  }),
);

interface DisableSubBusinessDialogProps {
  open: boolean;
  handleClose(): void;
  handleConfirm(): void;
  disabledButton: boolean;
}

function DisableSubBusinessDialog({
  open,
  handleClose,
  handleConfirm,
  disabledButton,
}: DisableSubBusinessDialogProps) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs" className={classes.container}>
      <DialogTitle className={classes.title}>
        ¿Estás seguro que deseas deshabilitar este comercio?
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>
          Si lo deshabilitas, todos los links creados para este comercio quedarán inactivos, y los clientes no
          podrán usarlos para pagar.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined" autoFocus>
          Cancelar
        </Button>
        <Button
          className={classes.deleteButton}
          onClick={handleConfirm}
          color="inherit"
          variant="contained"
          disabled={disabledButton}
        >
          {disabledButton ? <CircularProgress size={24} color="inherit" /> : 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DisableSubBusinessDialog;
