import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIssuers } from '../../../store/action_creators/paymentMethods.actions';
import { PaymentMethod, RootState } from '../../../store/config/types';
import { Client } from '../../../store/config/types/clients.types';
import LogoutDialog from '../../Header/LogoutDialog';
import { Loading } from '../../Loading';
import { PaymentMethodForm } from '../../forms/PaymentMethodForm';

interface PaymentMethodDialogProps {
  open: boolean;
  setOpen(value: boolean): void;
  paymentMethod: PaymentMethod | null;
  firstPaymentMethod?: boolean;
  client?: Client;
}

function PaymentMethodDialog({
  open,
  setOpen,
  paymentMethod,
  firstPaymentMethod = false,
  client,
}: PaymentMethodDialogProps) {
  const dispatch = useDispatch();
  const paymentMethods = useSelector((state: RootState) => state.paymentMethods);
  const [logoutOpen, setLogoutOpen] = useState<boolean>(false);

  const closeDialog = () => setOpen(false);

  useEffect(() => {
    if (
      !paymentMethods.loadingIssuers &&
      paymentMethods.issuers === null &&
      paymentMethods.issuersErrorMessage === null
    ) {
      dispatch(fetchIssuers());
    }
  }, [paymentMethods.loadingIssuers, paymentMethods.issuers, paymentMethods.issuersErrorMessage, dispatch]);

  const openLogout = () => {
    setLogoutOpen(true);
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xs" className="dialog">
      <DialogTitle classes={{ root: 'dialog-title' }}>
        <CloseIcon
          fontSize="default"
          className="icon"
          onClick={firstPaymentMethod ? openLogout : closeDialog}
        />
      </DialogTitle>
      {paymentMethods.issuers ? (
        <>
          <DialogContent classes={{ root: 'dialog-content' }}>
            <PaymentMethodForm
              paymentMethod={paymentMethod}
              firstPaymentMethod={firstPaymentMethod}
              handleSuccess={closeDialog}
              client={client}
              isDialog
            />
          </DialogContent>
        </>
      ) : (
        <Loading />
      )}
      <LogoutDialog open={logoutOpen} setOpen={setLogoutOpen} />
    </Dialog>
  );
}

export default PaymentMethodDialog;
