import { SVGProps } from 'react';

function BoltMarginlessIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.31955 5.99928H6.07857L7.27184 1.94398C7.29805 1.83289 7.30105 1.71661 7.28061 1.60404C7.26016 1.49146 7.21681 1.38558 7.15388 1.29448C7.09095 1.20338 7.0101 1.12949 6.91751 1.07845C6.82493 1.0274 6.72306 1.00057 6.61971 1H2.57045C2.40644 1.00036 2.24815 1.06699 2.12509 1.18746C2.00203 1.30794 1.9226 1.47404 1.90163 1.65478L1.00594 9.14879C0.993212 9.25475 1.001 9.36251 1.02877 9.46486C1.05654 9.5672 1.10366 9.66178 1.16698 9.74226C1.2303 9.82274 1.30835 9.88727 1.39592 9.93154C1.48349 9.9758 1.57855 9.99877 1.67476 9.99892H5.01395L3.71756 16.0764C3.67964 16.2517 3.69993 16.4364 3.77472 16.5964C3.84951 16.7564 3.97376 16.8811 4.12464 16.9474C4.27552 17.0138 4.44289 17.0174 4.59599 16.9577C4.74908 16.8979 4.87762 16.7787 4.95797 16.6221L9.90685 7.12769C9.96739 7.01343 9.99953 6.88337 9.99999 6.75078C10.0005 6.6182 9.96922 6.48785 9.90949 6.37308C9.84975 6.25831 9.76365 6.16322 9.66 6.09754C9.55635 6.03187 9.43886 5.99796 9.31955 5.99928ZM4.94128 14.6512L5.88313 10.227L6.14437 8.99601H1.92618L2.76785 1.99964H6.30936L5.21921 5.68716L4.83619 7.00219H8.92867L4.94128 14.6512Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.4"
      />
    </svg>
  );
}

export default BoltMarginlessIcon;
