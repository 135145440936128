import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Search from '../../components/FiltersComponent/Search';
import { Filters } from '../../components/Icons/Filters';
import { PaymentsCards } from '../../components/PaymentsCards';
import { fetchAllUsers } from '../../store/action_creators/collaborators.actions';
import { fetchPaymentMethods } from '../../store/action_creators/paymentMethods.actions';
import { UserTypeEnum } from '../../store/config/enums';
import { PaymentsTable } from './../../components/PaymentsTable';
import { TotalsAccordion } from './../../components/TotalsAccordion';
import { fetchPayments, loadPaymentsFilters } from './../../store/action_creators/payments.actions';
import { Business, RootState } from './../../store/config/types';
import PaymentsFilters from './PaymentsFilters';
import PaymentsScreenTitle from './PaymentsScreenTitle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexContainer: {
      display: 'flex',
      gap: 8,
    },
  }),
);

function Payments() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { auth, payments, paymentMethods, collaborators } = useSelector((state: RootState) => state);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedBusiness, setSelectedBusiness] = useState<Business | null>(null);
  const [open, setOpen] = useState(false);
  const [showPlexoNames, setShowPlexoNames] = useState(false);
  const isSuperAdmin = auth.account?.type === UserTypeEnum.SUPERADMIN;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const businessId = isSuperAdmin
    ? selectedBusiness === null
      ? 0
      : selectedBusiness.id!
    : auth.account?.business?.id ?? -1;

  useEffect(() => {
    if (
      auth.account &&
      !paymentMethods.loadingPaymentMethods &&
      paymentMethods.paymentMethods === null &&
      paymentMethods.paymentMethodsErrorMessage === null
    ) {
      dispatch(fetchPaymentMethods(businessId));
    }
  }, [
    auth.account,
    paymentMethods.loadingPaymentMethods,
    paymentMethods.paymentMethods,
    paymentMethods.paymentMethodsErrorMessage,
    businessId,
    dispatch,
  ]);

  useEffect(() => {
    if (
      auth.account &&
      !collaborators.loadingCollaborators &&
      collaborators.collaborators === null &&
      collaborators.collaboratorsErrorMessage === null
    ) {
      dispatch(fetchAllUsers(businessId));
    }
  }, [
    auth.account,
    collaborators.loadingCollaborators,
    collaborators.collaborators,
    collaborators.collaboratorsErrorMessage,
    businessId,
    dispatch,
  ]);

  const search = () => {
    if (
      (searchTerm.length > 3 || searchTerm.length === 0) &&
      searchTerm !== payments.filters.term &&
      businessId !== -1
    ) {
      const filters = { ...payments.filters, term: searchTerm };
      dispatch(loadPaymentsFilters(filters, payments.currentPage, payments.pageSize, businessId));
    }
  };

  const loadNextPage = () => {
    dispatch(fetchPayments(payments.filters, payments.currentPage + 1, payments.pageSize, businessId));
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <div
      className={clsx(
        'screen-container',
        'payments',
        auth.account?.type === UserTypeEnum.SUPERADMIN && 'screen-container-extended',
      )}
    >
      <PaymentsScreenTitle
        businessId={businessId}
        isDesktop={isDesktop}
        isSuperAdmin={isSuperAdmin}
        showPlexoNames={showPlexoNames}
        setShowPlexoNames={setShowPlexoNames}
      />
      {isDesktop && (
        <PaymentsFilters
          selectedBusiness={selectedBusiness}
          setSelectedBusiness={setSelectedBusiness}
          businessId={businessId}
          search={search}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          showPlexoNames={showPlexoNames}
        />
      )}
      {isDesktop && <TotalsAccordion />}
      {!isDesktop ? (
        <>
          <div className={classes.flexContainer}>
            <Search
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              triggerSearch={search}
              placeholder="Buscar por descripción, referencia o importe"
            />
            <Button variant="outlined" color="secondary" onClick={() => setOpen(true)}>
              <Filters />
            </Button>
          </div>
          <PaymentsCards loadNextPage={loadNextPage} />
          <Dialog open={open} fullWidth maxWidth="sm" onClose={closeDialog} className="dialog">
            <DialogTitle classes={{ root: 'dialog-title' }}>
              <CloseIcon fontSize="default" className="icon" onClick={closeDialog} />
            </DialogTitle>
            <DialogContent classes={{ root: 'dialog-content' }}>
              <Typography variant="h4">Filtrar por</Typography>
              <PaymentsFilters
                selectedBusiness={selectedBusiness}
                setSelectedBusiness={setSelectedBusiness}
                businessId={businessId}
                search={search}
                closeDialog={closeDialog}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <PaymentsTable showPlexoNames={showPlexoNames} />
      )}
    </div>
  );
}

export default Payments;
