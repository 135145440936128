import { useSelector } from 'react-redux';
import { Status } from '../../../store/config/enums';
import { RootState } from '../../../store/config/types';
import { CustomSnackbar } from '../../CustomSnackbar';

interface SnackbarManagerProps {
  creatingPaymentMethod: boolean;
  updatingPaymentMethod: boolean;
  handleClose: () => void;
  firstPaymentMethod?: boolean;
}

function SnackbarManager({
  creatingPaymentMethod,
  updatingPaymentMethod,
  handleClose,
  firstPaymentMethod,
}: SnackbarManagerProps) {
  const paymentMethods = useSelector((state: RootState) => state.paymentMethods);

  return (
    <>
      <CustomSnackbar
        open={
          creatingPaymentMethod &&
          (paymentMethods.createPaymentMethodSuccess ||
            paymentMethods.createPaymentMethodErrorMessage !== null)
        }
        message={
          paymentMethods.createPaymentMethodSuccess
            ? firstPaymentMethod
              ? 'Tu primer medio de pago ha sido añadido exitosamente'
              : 'Se creó el medio de pago correctamente'
            : paymentMethods.createPaymentMethodErrorMessage!
        }
        handleClose={handleClose}
        type={paymentMethods.createPaymentMethodSuccess ? Status.SUCCESS : Status.ERROR}
      />
      <CustomSnackbar
        open={
          updatingPaymentMethod &&
          (paymentMethods.updatePaymentMethodSuccess ||
            paymentMethods.updatePaymentMethodErrorMessage !== null)
        }
        message={
          paymentMethods.updatePaymentMethodSuccess
            ? 'Se actualizó el medio de pago correctamente'
            : paymentMethods.updatePaymentMethodErrorMessage!
        }
        handleClose={handleClose}
        type={paymentMethods.updatePaymentMethodSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </>
  );
}

export default SnackbarManager;
