import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    plexoCommerceName: {
      marginBottom: '1.25rem',
    },
  }),
);

interface PlexoCommerceTextProps {
  plexoCommerceName: string;
}

function PlexoCommerceText({ plexoCommerceName }: PlexoCommerceTextProps) {
  const classes = useStyles();

  return (
    <Typography variant="body2" className={classes.plexoCommerceName}>
      Este comercio está registrado en Plexo con el nombre: {plexoCommerceName}
    </Typography>
  );
}

export default PlexoCommerceText;
