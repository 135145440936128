import { PaymentTypeEnum } from '../store/config/enums';

const paymentTypesTitle: Record<PaymentTypeEnum, string> = {
  [PaymentTypeEnum.Regular]: 'Tradicional',
  [PaymentTypeEnum.Split]: 'Split payments',
};

const paymentTypesSubtitle: Record<PaymentTypeEnum, string> = {
  [PaymentTypeEnum.Regular]:
    'Procesa el pago completo hacia un único destino, ideal para transacciones sencillas.',
  [PaymentTypeEnum.Split]:
    'Divide el pago en dos partes, cada una con su propio destino y condiciones específicas.',
};

export const paymentTypeHelper = { paymentTypesTitle, paymentTypesSubtitle };
