import { Button, CircularProgress, createStyles, Divider, makeStyles, Theme } from '@material-ui/core';
import { FormikState } from 'formik/dist/types';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScreenTitle } from '../../components/ScreenTitle';
import { IssuersCommand, SubBusiness } from '../../store/config/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      minWidth: 'unset',
    },
  }),
);

interface SubBusinessActionsProps {
  selectedSubBusiness: SubBusiness | undefined;
  setDisableDialogOpen: Dispatch<SetStateAction<boolean>>;
  changeSubBusinessStatus: () => void;
  changingSubBusinessStatus: boolean;
  subBusinessEnabled: boolean;
  creatingSubBusiness: boolean;
  updatingSubBusiness: boolean;
  paymentMethodsToAdd: IssuersCommand[];
  values: FormikState<{ name: string }>;
}

function SubBusinessActions({
  selectedSubBusiness,
  setDisableDialogOpen,
  changeSubBusinessStatus,
  changingSubBusinessStatus,
  subBusinessEnabled,
  creatingSubBusiness,
  updatingSubBusiness,
  paymentMethodsToAdd,
  values,
}: SubBusinessActionsProps) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <ScreenTitle
      title={`Split payments/${selectedSubBusiness ? selectedSubBusiness.name : 'Nuevo comercio'}`}
    >
      {selectedSubBusiness ? (
        <>
          <Button
            onClick={subBusinessEnabled ? () => setDisableDialogOpen(true) : () => changeSubBusinessStatus()}
            variant="outlined"
            color="secondary"
            disabled={changingSubBusinessStatus}
          >
            {changingSubBusinessStatus ? (
              <CircularProgress color="inherit" size={28} />
            ) : subBusinessEnabled ? (
              'Deshabilitar'
            ) : (
              'Habilitar'
            )}
          </Button>
          <Divider orientation="vertical" flexItem />
          <Button
            className={classes.backButton}
            onClick={() => navigate(-1)}
            variant="outlined"
            color="secondary"
          >
            Volver
          </Button>
        </>
      ) : (
        <>
          <Button
            className={classes.backButton}
            onClick={() => navigate(-1)}
            variant="outlined"
            color="secondary"
          >
            Volver
          </Button>
          <Button
            type="submit"
            form="sub-business"
            color="primary"
            variant="contained"
            disabled={
              creatingSubBusiness ||
              updatingSubBusiness ||
              paymentMethodsToAdd.length === 0 ||
              !values.values.name
            }
          >
            {creatingSubBusiness || updatingSubBusiness ? (
              <CircularProgress color="inherit" size={28} />
            ) : (
              'Crear comercio'
            )}
          </Button>
        </>
      )}
    </ScreenTitle>
  );
}

export default SubBusinessActions;
