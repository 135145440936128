import { Card, Theme, createStyles, makeStyles } from '@material-ui/core';
import { BusinessIssuer, LinkIssuer } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paymentMethodsCard: {
      display: 'flex',
      marginBottom: '0.625rem',
      padding: '0.625rem 1rem',
      cursor: 'pointer',
      boxShadow: styles.boxShadowSmall,
    },
    paymentMethodsCardSelected: {
      display: 'flex',
      marginBottom: '0.625rem',
      padding: '0.625rem 1rem',
      cursor: 'pointer',
      boxShadow: styles.boxShadowSmall,
      backgroundColor: styles.primaryShade,
      border: `1px solid ${styles.primary}`,
    },
    imageContainer: {
      backgroundColor: styles.white,
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      padding: '0.4rem 0',
      border: `1px solid ${styles.greyTableHead}`,
      marginRight: '0.8125rem',
      '& img': {
        width: '80%',
      },
    },
    paymentMethodsDetails: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& h2': {
        fontSize: '0.75rem',
        fontWeight: Number(styles.normalTextWeight),
        color: styles.darkBlue,
        margin: 0,
      },
      '& p': {
        fontSize: '0.75rem',
        fontWeight: Number(styles.normalTextWeight),
        color: styles.slateBlue,
        margin: 0,
      },
    },
  }),
);

interface IssuerCardProps {
  issuer: BusinessIssuer | LinkIssuer;
  selectedIssuer?: BusinessIssuer | null;
  clickAction?: (issuer: BusinessIssuer) => void;
}

function IssuerCard({ issuer, selectedIssuer, clickAction }: IssuerCardProps) {
  const maxInstallments = issuer.values.sort((a, b) => b - a)[0];
  const classes = useStyles();

  return (
    <Card
      className={
        selectedIssuer?.id === issuer.id ? classes.paymentMethodsCardSelected : classes.paymentMethodsCard
      }
      onClick={() => {
        clickAction && clickAction(issuer);
      }}
    >
      <div className={classes.imageContainer}>
        <img src={issuer.issuerImageUrl} alt={issuer.issuerName} />
      </div>
      <div className={classes.paymentMethodsDetails}>
        <h2>{issuer.issuerName}</h2>
        <p>{`Hasta en ${maxInstallments} ${maxInstallments === 1 ? 'cuota' : 'cuotas'}`}</p>
      </div>
    </Card>
  );
}

export default IssuerCard;
