import { Button, Card, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Field, Form } from 'formik';
import { FormTextField } from '../../components/forms/FormTextField';
import { SubBusiness } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameCard: {
      margin: '1.75rem 0',
      padding: '1.25rem',
      '& p': {
        margin: 0,
        color: styles.iconColor,
        fontSize: '0.875rem',
        fontWeight: +styles.normalFontWeight,
      },
    },
    informationCol: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 1,
      '& p': {
        color: styles.slateBlue,
        margin: '0.8rem 0 -0.6rem 0 ',
      },
    },
    fieldAndButton: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '1rem',
      '& div:first-child': {
        margin: 0,
      },
      '& button': {
        marginLeft: '1rem',
        width: '12rem',
        height: '3.6rem',
      },
    },
  }),
);

interface SubBusinessFormProps {
  selectedSubBusiness: SubBusiness | undefined;
  updatingSubBusiness: boolean;
  creatingSubBusiness: boolean;
}

function SubBusinessForm({
  selectedSubBusiness,
  updatingSubBusiness,
  creatingSubBusiness,
}: SubBusinessFormProps) {
  const classes = useStyles();

  return (
    <Form id="sub-business" className="form">
      <Card className={classes.nameCard}>
        <p>
          Ingresa los datos del comercio que deseas configurar. Para habilitarlo y asociarle links, asegúrate
          de configurar al menos un medio de pago
        </p>
        <div className={classes.informationCol}>
          <p>Nombre del comercio</p>
          <div className={classes.fieldAndButton}>
            <Field name="name" component={FormTextField} type="text" />
            {selectedSubBusiness && (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                form="sub-business"
                disabled={updatingSubBusiness || creatingSubBusiness}
              >
                {updatingSubBusiness ? <CircularProgress color="inherit" /> : 'Guardar'}
              </Button>
            )}
          </div>
        </div>
      </Card>
    </Form>
  );
}

export default SubBusinessForm;
