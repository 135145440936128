import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { issuerHelper } from '../../../helpers/issuerHelper';
import { Issuer, PaymentMethod, RootState } from '../../../store/config/types';
import { FormSelectField } from '../FormSelectField';

interface IssuerAndAcquirerProps {
  paymentMethod: PaymentMethod | null;
  selectedIssuer: Issuer | null;
  installmentsField: ReactElement | boolean;
}

function IssuerAndAcquirer({ paymentMethod, selectedIssuer, installmentsField }: IssuerAndAcquirerProps) {
  const paymentMethods = useSelector((state: RootState) => state.paymentMethods);

  const issuersList = issuerHelper.getIssuersList(paymentMethods, paymentMethod);

  return (
    <>
      <Grid item xs={12}>
        <Field
          name="issuer"
          component={FormSelectField}
          disabled={paymentMethod !== null}
          label="Medio de pago"
          options={issuersList}
        />
      </Grid>
      <Grid item xs={12}>
        {installmentsField}
      </Grid>
      {selectedIssuer?.paymentProcessors && (
        <Grid item xs={12}>
          <Field
            name="acquirer"
            component={FormSelectField}
            label="Adquirente"
            options={
              selectedIssuer?.paymentProcessors
                ? selectedIssuer?.paymentProcessors.map((pp) => {
                    return {
                      id: pp.acquirer,
                      value: pp.acquirer,
                      disabled: false,
                    };
                  })
                : []
            }
          />
        </Grid>
      )}
    </>
  );
}

export default IssuerAndAcquirer;
