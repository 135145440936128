import {
  Button,
  Card,
  Chip,
  CircularProgress,
  Grid,
  TextareaAutosize,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import { Amount, Business, BusinessIssuer, LandingParams, LinkToPay } from '../../../store/config/types';
import styles from '../../../styles/_variables.module.scss';
import IssuerCard from '../../IssuersCardList/IssuerCard';
import { LinkDetailCard } from '../LinkDetailCard';
import { FloatingButtons } from '../MobileNavigation';
import { OnTheFlyDetailCard } from '../OnTheFlyDetailCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0 auto',
      gap: '3rem',
      width: '70%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        width: '100%',
        gap: '1rem',
      },
    },
    cardRight: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      height: 'max-content',
      flexGrow: 1,
      padding: '2.375rem',
      marginBottom: '9.375rem',
    },
    title: {
      color: styles.slateBlue,
      fontSize: '1.125rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      margin: '0 0 1.1875rem 0',
    },
    installmentsBox: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(2.8125rem, 1fr))',
      justifyItems: 'center',
      alignItems: 'center',
      gap: '0.9375rem',
      margin: '1.2rem auto',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      '& h3': {
        marginBottom: '0',
        color: styles.secondaryMain,
        fontSize: '1.125rem',
        fontWeight: Number(styles.semiBoldTextWeight),
      },
    },
    commentLabel: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'end',
      '& i': {
        fontSize: '0.875rem',
        margin: '0.8rem 0 -0.6rem 0 ',
        color: styles.almostGrey,
      },
    },
    textarea: {
      padding: '0.5625rem 1.5625rem 0.5625rem 0.625rem',
      color: styles.slateBlue,
      border: `1px solid ${styles.borderButtonSecondary}`,
      borderRadius: '4px',
      resize: 'none',
      fontFamily: 'Rubik',
      fontSize: '1rem',
      '&::placeholder': {
        color: styles.slateBlue,
      },
      '&:focus': {
        border: '1px solid transparent',
        outline: `${styles.primary} solid 2px`,
      },
      margin: '1rem 0',
      width: '100%',
    },
    actionButtons: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    backButton: {
      width: '25%',
    },
    payButton: {
      marginLeft: '1rem',
      width: '40%',
    },
  }),
);

interface InstallmentProps {
  submit: (values: InstallmentValues) => void;
  issuer: BusinessIssuer;
  beginningPayment: boolean;
  linkToPay: LinkToPay;
  business: Business | null;
  step: number;
  setStep: (step: number) => void;
  params: LandingParams;
  selectedAmount?: Amount | null;
}

interface InstallmentValues {
  installments: number;
  comment: string;
}

function ConsumerInstallments({
  submit,
  issuer,
  beginningPayment,
  linkToPay,
  business,
  step,
  setStep,
  params,
  selectedAmount,
}: InstallmentProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const numericInstallments = issuer.values.map((installment) => Number(installment));
  const sortedInstallments = numericInstallments.sort((a, b) => a - b);

  const vatRate = linkToPay?.vatRate || business?.vatRate || 0;
  const amount = linkToPay?.amount || selectedAmount;

  return (
    <div className={classes.container}>
      {!linkToPay ? (
        <OnTheFlyDetailCard business={business!} params={params} amount={selectedAmount} />
      ) : (
        <LinkDetailCard business={business} linkToPay={linkToPay} />
      )}
      <Card className={classes.cardRight}>
        <h1 className={classes.title}>Medio de pago</h1>
        <IssuerCard issuer={issuer} />
        <Formik
          initialValues={{
            installments: sortedInstallments[0],
            comment: '',
          }}
          onSubmit={submit}
        >
          {({ values, setFieldValue }) => (
            <Form className={classes.form} key="installments" id="installments">
              <h3>Selecciona las cuotas</h3>
              <Field
                name="installments"
                render={({ field }: any) => (
                  <Grid item xs={12}>
                    <div className={classes.installmentsBox}>
                      {sortedInstallments.map((installment, index) => (
                        <Chip
                          key={index}
                          label={installment}
                          clickable
                          color={values.installments === installment ? 'primary' : 'default'}
                          onClick={() => {
                            setFieldValue('installments', installment);
                          }}
                        />
                      ))}
                    </div>
                  </Grid>
                )}
              />
              <div className={classes.commentLabel}>
                <i>Restan {50 - values.comment?.length!} caracteres</i>
              </div>
              <Field
                name="comment"
                render={({ field }: any) => (
                  <TextareaAutosize
                    {...field}
                    auto
                    className={classes.textarea}
                    rowsMin={3}
                    defaultValue=""
                    placeholder="Puedes agregar un comentario (opcional)"
                    maxLength={50}
                  />
                )}
                type="text"
              />
              {isMobile ? (
                <FloatingButtons
                  step={step}
                  setStep={setStep}
                  selectedIssuer={issuer}
                  amount={amount.value}
                  currency={amount.currency}
                  vatRate={vatRate}
                  formId="installments"
                  submitting={beginningPayment}
                />
              ) : (
                <div className={classes.actionButtons}>
                  <Button
                    className={classes.backButton}
                    onClick={() => setStep(1)}
                    variant="outlined"
                    color="secondary"
                    disabled={beginningPayment}
                  >
                    Volver
                  </Button>
                  <Button
                    disabled={beginningPayment}
                    type="submit"
                    className={classes.payButton}
                    variant="contained"
                    color="primary"
                  >
                    {beginningPayment ? <CircularProgress size={28} color="inherit" /> : 'Continuar'}
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
}

export default ConsumerInstallments;
