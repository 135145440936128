import { IconProps } from '../../../data/icon.type';
import colors from '../../../styles/_variables.module.scss';

function AddPaymentMethodIcon({ color, className }: IconProps) {
  color = color ?? colors.slateBlue;
  className = className ?? '';

  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="29" height="29" rx="3.5" fill="white" stroke="#D7DBEC" />
      <path
        opacity="0.904"
        d="M13.8708 8.54292C13.8708 8.39437 13.9001 8.24728 13.9569 8.11004C14.0137 7.9728 14.0971 7.84809 14.2021 7.74305C14.3072 7.63801 14.4319 7.55469 14.5691 7.49784C14.7063 7.441 14.8534 7.41174 15.002 7.41174C15.1505 7.41174 15.2976 7.441 15.4349 7.49785C15.5721 7.5547 15.6968 7.63802 15.8018 7.74306C15.9069 7.8481 15.9902 7.9728 16.0471 8.11004C16.1039 8.24728 16.1332 8.39438 16.1332 8.54292L16.1341 13.8129L21.4031 13.8148C21.7008 13.8188 21.985 13.9398 22.1941 14.1517C22.4032 14.3635 22.5205 14.6493 22.5205 14.947C22.5205 15.2447 22.4032 15.5305 22.1941 15.7424C21.985 15.9543 21.7008 16.0753 21.4031 16.0792L16.1312 16.0782L16.1312 21.3491C16.1379 21.5018 16.1136 21.6543 16.0598 21.7974C16.0059 21.9405 15.9237 22.0712 15.818 22.1816C15.7123 22.2921 15.5853 22.38 15.4447 22.44C15.3042 22.5 15.1529 22.531 15 22.531C14.8471 22.531 14.6958 22.5 14.5553 22.44C14.4147 22.38 14.2877 22.2921 14.182 22.1816C14.0763 22.0712 13.9941 21.9405 13.9402 21.7974C13.8864 21.6543 13.8621 21.5018 13.8688 21.3491L13.8708 16.0762L8.59789 16.0762C8.30019 16.0723 8.01602 15.9513 7.80689 15.7394C7.59776 15.5275 7.4805 15.2418 7.4805 14.944C7.4805 14.6463 7.59776 14.3606 7.80689 14.1487C8.01602 13.9368 8.3002 13.8158 8.59789 13.8119L13.8698 13.8129L13.8708 8.54292Z"
        fill={color}
      />
    </svg>
  );
}

export default AddPaymentMethodIcon;
