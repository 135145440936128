import { Dispatch, SetStateAction, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClientsFilter } from '../../components/ClientsFilter';
import { FiltersComponent } from '../../components/FiltersComponent';
import { fetchPayments, loadPaymentsFilters } from '../../store/action_creators/payments.actions';
import { UserTypeEnum } from '../../store/config/enums';
import { Business, PaymentsFilters as PaymentsFiltersInterface, RootState } from '../../store/config/types';

interface PaymentsFiltersProps {
  selectedBusiness: Business | null;
  setSelectedBusiness(business: Business | null): void;
  businessId: number;
  search(): void;
  closeDialog?(): void;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  showPlexoNames?: boolean;
}

function PaymentsFilters({
  selectedBusiness,
  setSelectedBusiness,
  businessId,
  closeDialog,
  search,
  searchTerm,
  setSearchTerm,
  showPlexoNames,
}: PaymentsFiltersProps) {
  const { auth, payments } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const loadFilters = (filters: PaymentsFiltersInterface) => {
    if (auth.account) {
      dispatch(loadPaymentsFilters(filters, 1, payments.pageSize, businessId));
    }
  };

  const filterByBusiness = (business: Business | null) => setSelectedBusiness(business);

  useEffect(() => {
    if (
      businessId !== -1 &&
      !payments.loadingPayments &&
      payments.payments === null &&
      payments.paymentsErrorMessage === null
    ) {
      dispatch(fetchPayments(payments.filters, payments.currentPage, payments.pageSize, businessId));
    }
  }, [
    payments.loadingPayments,
    payments.payments,
    payments.paymentsErrorMessage,
    payments.filters,
    payments.pageSize,
    auth.account,
    businessId,
    payments.currentPage,
    dispatch,
  ]);

  return (
    <FiltersComponent
      hasDateFilter
      hasIssuerFilter
      hasCollaboratorFilter
      clientsFilter={
        auth.account?.type === UserTypeEnum.SUPERADMIN && (
          <ClientsFilter filterByBusiness={filterByBusiness} showPlexoNames={showPlexoNames} />
        )
      }
      selectedBusiness={selectedBusiness}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      filters={payments.filters}
      setFilters={loadFilters}
      triggerSearch={search}
      closeDialog={closeDialog}
      isPaymentFilter
    />
  );
}

export default PaymentsFilters;
