import { Chip, Grid, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PIX_ISSUER_ID } from '../../../data/constants';
import { fetchInstallments } from '../../../store/action_creators/paymentMethods.actions';
import { Issuer, PaymentMethod, RootState } from '../../../store/config/types';
import colors from '../../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helperText: {
      paddingTop: '0.5rem',
    },
    subtitle: {
      marginBottom: '0.6rem',
      color: colors.slateBlue,
      fontWeight: 500,
      lineHeight: 1.57,
      fontSize: '0.9375rem',
    },
    installmentsBox: {
      border: `1px solid ${colors.borderButtonSecondary}`,
      padding: '1.0625rem 1.25rem',
      borderRadius: '4px',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(2.8125rem, 1fr))',
      justifyItems: 'center',
      alignItems: 'center',
      gap: '0.9375rem',
    },
    installment: {
      flex: 1,
      textAlign: 'center',
      padding: '0.625rem',
    },
  }),
);

interface InstallmentsFieldProps {
  paymentMethod: PaymentMethod | null;
  selectedInstallments: number[];
  setSelectedInstallments(installments: number[]): void;
  selectedIssuer?: Issuer | null;
}

function InstallmentsField({
  paymentMethod,
  selectedInstallments,
  setSelectedInstallments,
  selectedIssuer,
}: InstallmentsFieldProps) {
  const dispatch = useDispatch();
  const paymentMethods = useSelector((state: RootState) => state.paymentMethods);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      setSelectedInstallments(paymentMethod ? paymentMethod.values : [1]);
    }
  }, [loaded, setLoaded, setSelectedInstallments, paymentMethod]);

  const classes = useStyles();

  useEffect(() => {
    if (
      !paymentMethods.fetchingPossibleInstallments &&
      paymentMethods.possibleInstallments === null &&
      paymentMethods.fetchInstallmentsError === null
    ) {
      dispatch(fetchInstallments());
    }
  }, [
    paymentMethods.fetchingPossibleInstallments,
    paymentMethods.possibleInstallments,
    paymentMethods.fetchInstallmentsError,
    dispatch,
  ]);

  useEffect(() => {
    if (!selectedInstallments.length) setSelectedInstallments([1]);
  }, [selectedInstallments.length, setSelectedInstallments]);

  const checkInstallment = (installment: number) => {
    const existingInstallment = selectedInstallments.find((i) => i === installment);

    if (existingInstallment) {
      setSelectedInstallments(selectedInstallments.filter((i) => i !== installment));
    } else {
      setSelectedInstallments([...selectedInstallments, installment]);
    }
  };

  return (
    <>
      {paymentMethods.possibleInstallments && (
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>Cuotas disponibles</Typography>
          <div className={classes.installmentsBox}>
            {paymentMethod?.id === PIX_ISSUER_ID || selectedIssuer?.issuerId === PIX_ISSUER_ID ? (
              <Chip label={1} clickable={false} color="default" disabled />
            ) : (
              paymentMethods.possibleInstallments.map((i, index) => {
                const isSelected = selectedInstallments.find((ins) => ins === i) !== undefined;

                return (
                  <Chip
                    key={index}
                    label={i}
                    clickable
                    color={isSelected ? 'primary' : 'default'}
                    onClick={() => checkInstallment(i)}
                  />
                );
              })
            )}
          </div>
        </Grid>
      )}
    </>
  );
}

export default InstallmentsField;
