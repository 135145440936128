import { createStyles, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { CustomSwitch } from '../../components/CustomSwitch';
import { ExportPayments } from '../../components/ExportPayments';
import { ScreenTitle } from '../../components/ScreenTitle';
import { RootState } from '../../store/config/types';
import colors from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexRow: {
      display: 'flex',
      marginBottom: '2.5rem',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '0.5rem',
      },
    },
    divider: {
      margin: '0 1rem 0 0.5rem',
      alignSelf: 'center',
      height: '2.5rem',
      backgroundColor: colors.borderButtonSecondary,
    },
    desktopContainer: {
      display: 'flex',
      alignItems: 'center',
      color: colors.slateBlue,
    },
  }),
);

interface PaymentsScreenTitleProps {
  businessId: number;
  isDesktop: boolean;
  isSuperAdmin: boolean;
  showPlexoNames: boolean;
  setShowPlexoNames: (value: boolean) => void;
}

function PaymentsScreenTitle({
  businessId,
  isDesktop,
  isSuperAdmin,
  showPlexoNames,
  setShowPlexoNames,
}: PaymentsScreenTitleProps) {
  const classes = useStyles();
  const { auth, payments } = useSelector((state: RootState) => state);

  return (
    <div className={classes.flexRow}>
      <ScreenTitle title="Pagos" elementsAmount={isDesktop ? payments.rowCount : undefined}>
        {isDesktop && (
          <div className={classes.desktopContainer}>
            {isSuperAdmin && (
              <>
                <Typography variant="body2">Mostrar nombre en Plexo</Typography>
                <CustomSwitch
                  checked={showPlexoNames}
                  onChange={() => setShowPlexoNames(!showPlexoNames)}
                  color="primary"
                  name="checkedB"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Divider orientation="vertical" className={classes.divider} />
              </>
            )}
            <ExportPayments auth={auth} payments={payments} businessId={businessId} />
          </div>
        )}
      </ScreenTitle>
    </div>
  );
}

export default PaymentsScreenTitle;
