import { AppBar, useMediaQuery, useTheme } from '@material-ui/core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import { UserTypeEnum } from '../../store/config/enums';
import { RootState } from '../../store/config/types';
import CogMenu from './CogMenu';
import DesktopNavigation from './DesktopNavigation';
import LogoutDialog from './LogoutDialog';
import MobileNavigation from './MobileNavigation/MobileNavigation';
import OnTheFlyButton from './OnTheFlyButton';
import UserMenu from './UserMenu';

function Header() {
  const navigate = useNavigate();
  const { auth } = useSelector((state: RootState) => state);
  const [open, setOpen] = useState<boolean>(false);
  const [mobileMenuOpened, setMobileMenuOpened] = useState<boolean>(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isSuperAdmin = auth.account?.type === UserTypeEnum.SUPERADMIN;
  const acceptsOnTheFly = !isSuperAdmin && auth.account && auth.account.business?.acceptsOnTheFly;
  const acceptsSplitPayments = auth.account?.business?.acceptsSplitPayments!;

  const goHome = () => {
    navigate('/dashboard');
  };

  return (
    <AppBar elevation={1} className="header">
      <div className="header-content">
        {isDesktop && (
          <button className="logo">
            <img src={logo} alt="Plexo Links logo" onClick={goHome} />
          </button>
        )}
        {isDesktop && <DesktopNavigation role={auth.account?.type} />}
        {!isDesktop && (
          <MobileNavigation
            role={auth.account?.type}
            mobileMenuOpened={mobileMenuOpened}
            setMobileMenuOpened={setMobileMenuOpened}
            acceptsSplitPayments={acceptsSplitPayments}
          />
        )}
        {isDesktop ? (
          <div className="configurations">
            {acceptsOnTheFly && <OnTheFlyButton />}
            {auth.account && auth.account?.type === UserTypeEnum.OWNER && (
              <>
                <CogMenu acceptsSplitPayments={acceptsSplitPayments} />
                <div className="divider" />
              </>
            )}
            <UserMenu setOpen={setOpen} />
          </div>
        ) : (
          <>{acceptsOnTheFly && <OnTheFlyButton />}</>
        )}
        <LogoutDialog open={open} setOpen={setOpen} />
      </div>
    </AppBar>
  );
}

export default Header;
