import { Divider, Paper, Theme, createStyles, makeStyles, useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { currencyHelper } from '../../helpers/currencyHelper';
import { dateHelper } from '../../helpers/dateHelper';
import { issuerCardHelper } from '../../helpers/issuerCardHelpers';
import { linkTypeEnumHelper } from '../../helpers/linkTypeEnumHelper';
import { numberHelper } from '../../helpers/numberHelper';
import { paymentStatusEnumHelper } from '../../helpers/paymentStatusEnumHelper';
import { paymentTypeHelper } from '../../helpers/paymentTypeHelper';
import { LinkTypeEnum, PaymentStatusEnum, UserTypeEnum } from '../../store/config/enums';
import { AuthState, Payment, RootState } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { theme } from '../../styles/theme';
import { Loading } from '../Loading';
import { StatusPill } from '../StatusPill';
import DetailRow from './DetailRow';
import LinkExternalIdRow from './LinkExternalIdRow';
import SplitPaymentDetail from './SplitPaymentDetail';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperRoot: {
      margin: 0,
      padding: '1.875rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'space-between',
      color: styles.slateBlue,
    },
    failedText: {
      color: styles.textButtonSecondary,
    },
    rightWidth: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      },
    },
    divider: {
      marginBottom: '1rem',
      height: '1px',
      width: '100%',
    },
    splitPaymentTitle: {
      color: styles.textButtonSecondary,
      fontSize: '0.875rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      marginBottom: '0.875rem',
    },
    amount: {
      display: 'flex',
      alignItems: 'baseline',
      flexDirection: 'row',
      alignSelf: 'flex-end',
      '& p': {
        '&:first-child': {
          marginRight: '0.3125rem',
        },
      },
      '& *': {
        fontWeight: Number(styles.semiBoldTextWeight),
        color: styles.secondaryMain,
        margin: 0,
        fontSize: '1.0625rem',
      },
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'flex-start',
        '& h5': {
          fontSize: '1rem',
        },
      },
    },
    amountDetail: {
      textAlign: 'right',
      margin: 0,
      color: styles.darkBlue,
      fontSize: '0.875rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.85rem',
      },
    },
    splitAmountDetail: {
      fontSize: '0.75rem',
      color: styles.icoColor,
      textAlign: 'right',
      margin: 0,
    },
    issuerImageContainer: {
      display: 'flex',
      alignItems: 'center',
      '& img': {
        height: '1rem',
      },
    },
    paymentMethod: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'start',
        flexDirection: 'column',
      },
      '& p': {
        margin: 0,
      },
      '& img': {
        width: 'auto',
        height: '1.5rem',
      },
    },
    cardIssuer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: '0.15rem',
      '& span': {
        margin: '0 0.5rem',
      },
    },
  }),
);

function PaymentDetailContent() {
  const classes = useStyles();
  const auth: AuthState = useSelector((state: RootState) => state.auth);
  const payment: Payment | null = useSelector((state: RootState) => state.payments.selectedPayment);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const failedPayment =
    payment?.status !== PaymentStatusEnum.APPROVED && payment?.status !== PaymentStatusEnum.CANCELLED;

  if (!payment) {
    return <Loading />;
  }

  const hasBillingEnabled =
    (auth.account !== null && auth.account.business !== null && auth.account.business.billingEnabled) ||
    auth.account?.type === UserTypeEnum.SUPERADMIN;
  const paymentCurrency = currencyHelper.getCurrencyToken(payment.amount.currency);
  const paymentAmount = payment.amount.value + (payment.splitPayment?.amount.value || 0);
  const formattedPaymentAmount = numberHelper.getNumberWithDots(paymentAmount);
  const totalTaxedAmount = payment.taxedAmount.value + (payment.splitPayment?.taxedAmount.value || 0);
  const formattedTaxedAmount = numberHelper.getNumberWithDots(totalTaxedAmount);
  const paymentIVA = numberHelper.getIVA(paymentAmount, {
    taxedAmount: totalTaxedAmount,
  });

  return (
    <Paper className={classes.paperRoot}>
      <DetailRow
        title="Estado"
        detail={
          <StatusPill
            label={paymentStatusEnumHelper.getStatusText(payment.status)}
            color={paymentStatusEnumHelper.getStatusColorVariant(payment.status)}
          />
        }
        isState
      />
      {failedPayment && (
        <DetailRow
          title="Motivo del fallo"
          detail={<i className={classes.failedText}>{payment.plexoResultText ?? 'Motivo no especificado'}</i>}
        />
      )}
      {isMobile && <Divider className={classes.divider} />}
      <DetailRow title="Fecha y hora del pago" detail={dateHelper.toShorterNiceString(payment.date)} />
      <Divider className={classes.divider} />
      <DetailRow title="Descripción" detail={payment.description} />
      {isMobile && <Divider className={classes.divider} />}
      <DetailRow
        title="Fecha y hora de creación del link"
        detail={payment.linkCreationDate ? dateHelper.toShorterNiceString(payment.linkCreationDate) : '-'}
      />
      {isMobile && <Divider className={classes.divider} />}
      <DetailRow
        title="Tipo de link"
        detail={
          <>
            <span>{linkTypeEnumHelper.getIcon(payment.linkType)}</span>
            {linkTypeEnumHelper.toNiceString(payment.linkType)}
          </>
        }
      />
      <DetailRow title="Tipo de pago" detail={paymentTypeHelper.paymentTypesTitle[payment.type]} />
      <Divider className={classes.divider} />
      {payment.splitPayment && (
        <>
          <SplitPaymentDetail
            order={1}
            amount={payment.amount}
            taxedAmount={payment.taxedAmount}
            status={payment.status}
            name={auth.account?.business?.prettyName || ''}
            titleClass={classes.splitPaymentTitle}
            rightWidthClass={classes.rightWidth}
            amountDetailClass={classes.splitAmountDetail}
            totalAmountClass={classes.amountDetail}
          />
          <Divider className={classes.divider} />
          <SplitPaymentDetail
            order={2}
            amount={payment.splitPayment.amount}
            taxedAmount={payment.splitPayment.taxedAmount}
            status={payment.splitPayment.status}
            name={payment.splitLinkDto?.subBusinessDto?.name || ''}
            titleClass={classes.splitPaymentTitle}
            rightWidthClass={classes.rightWidth}
            amountDetailClass={classes.splitAmountDetail}
            totalAmountClass={classes.amountDetail}
          />
          <Divider className={classes.divider} />
        </>
      )}
      <DetailRow title="Cuotas" detail={payment.installments ? String(payment.installments) : '1'} />
      <DetailRow
        title="Importe total"
        detail={
          <div className={classes.rightWidth}>
            <div className={classes.amount}>
              <p>{paymentCurrency}</p>
              <p>{formattedPaymentAmount}</p>
            </div>
            <p
              className={classes.amountDetail}
            >{`${paymentCurrency} ${formattedTaxedAmount} + ${paymentCurrency} ${paymentIVA} (IVA)`}</p>
          </div>
        }
      />
      <Divider className={classes.divider} />
      {payment.linkType !== LinkTypeEnum.ONTHEFLY && (
        <DetailRow title="Referencia" detail={payment.referencePayment} />
      )}
      <DetailRow title="Comentarios" detail={payment.clientComments} />
      <Divider className={classes.divider} />
      <DetailRow title="Id de transacción en Plexo" detail={payment.plexoReferenceId} />
      {isMobile && <Divider className={classes.divider} />}
      <DetailRow title="Código de autorización" detail={payment.authorization} />
      {isMobile && <Divider className={classes.divider} />}
      <DetailRow title="Ticket" detail={payment.ticket} />
      {isMobile && <Divider className={classes.divider} />}
      <DetailRow title="N° de referencia" detail={payment.plexoReferenceId} />
      {isMobile && <Divider className={classes.divider} />}
      <DetailRow
        title="Medio de pago"
        detail={
          <div className={classes.paymentMethod}>
            <div className={classes.cardIssuer}>
              {issuerCardHelper.getBankLogoAndImage(payment.cardIssuer)}
            </div>
            {!isMobile && <Divider orientation="vertical" flexItem style={{ margin: '0.8rem 0' }} />}
            <div className={classes.issuerImageContainer}>
              <img src={payment.issuerLogoUrl} alt={payment.issuerName} />
              <p>•• {payment.maskedPan.slice(-4)}</p>
            </div>
          </div>
        }
      />
      {payment.linkType !== LinkTypeEnum.ONTHEFLY && (
        <>
          {isMobile && <Divider className={classes.divider} />}
          <DetailRow title="Id del link" detail={payment.linkId!} />
          {isMobile && <Divider className={classes.divider} />}
          <LinkExternalIdRow payment={payment} />
        </>
      )}
      {hasBillingEnabled && (
        <>
          <Divider className={classes.divider} />
          <DetailRow title="N° de factura" detail={payment.invoiceNumber} />
        </>
      )}
    </Paper>
  );
}

export default PaymentDetailContent;
