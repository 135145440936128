import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { FieldProps } from 'formik';
import React, { ChangeEvent } from 'react';

const useSelectStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
      display: 'flex',
    },
    image: {
      height: 'auto',
      width: 'auto',
      maxWidth: '60px',
      maxHeight: '30px',
      marginRight: '1rem',
    },
    item: {
      display: 'flex',
    },
    errorText: {
      margin: 0,
      marginTop: '0.4rem',
      color: theme.palette.error.main,
    },
  }),
);

interface Option {
  id: string;
  value: string;
  disabled?: boolean;
}

interface SelectProps {
  label: string;
  options: Option[];
}

type FormSelectProps = FieldProps & React.HTMLProps<HTMLSelectElement> & SelectProps;

function FormSelectField({ field, form, label, options, ...props }: FormSelectProps) {
  const classes = useSelectStyles();
  const hasError = (form.touched[field.name] && form.errors[field.name]) !== undefined;

  const onSelectChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
    form.setFieldValue(field.name, event.target.value);
  };

  return (
    <FormControl
      className={props.className}
      margin="normal"
      variant="outlined"
      classes={{ root: classes.root }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        classes={{ select: classes.select }}
        value={field.value}
        onChange={onSelectChange}
        label={label}
        disabled={props.disabled}
        error={hasError}
      >
        {options.map((option, index) => {
          if (option.disabled) {
            return null;
          }

          return (
            <MenuItem disabled={option.value === 'Selecciona una opción'} value={option.id} key={index}>
              {option.value}
            </MenuItem>
          );
        })}
      </Select>
      {hasError && (
        <FormHelperText classes={{ contained: classes.errorText }}>{form.errors[field.name]}</FormHelperText>
      )}
    </FormControl>
  );
}

export default FormSelectField;
