import { Divider, Theme, createStyles, makeStyles } from '@material-ui/core';
import { LinkTypeEnum } from '../../../store/config/enums';
import { LinkToPay } from '../../../store/config/types';
import styles from '../../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descriptionRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '1.5625rem 0',
      gap: '4rem',
      '& p': {
        color: styles.slateBlue,
        fontSize: '0.75rem',
        fontWeight: Number(styles.normalTextWeight),
        margin: 0,
        textAlign: 'end',
      },
      '&:last-child': {
        margin: '1.5625rem 0 0 0',
      },
    },
  }),
);

interface PaymentDetailsProps {
  linkToPay: LinkToPay;
}

function PaymentDetails({ linkToPay }: PaymentDetailsProps) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.descriptionRow}>
        <p>Descripción</p>
        <p>{linkToPay.description}</p>
      </div>
      {linkToPay.linkType === LinkTypeEnum.ONETIME && (
        <>
          <Divider />
          <div className={classes.descriptionRow}>
            <p>Referencia</p>
            <p>{linkToPay.reference ? linkToPay.reference : '-'}</p>
          </div>
        </>
      )}
    </>
  );
}

export default PaymentDetails;
