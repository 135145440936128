import { Slide, Snackbar, SnackbarContent, makeStyles } from '@material-ui/core';
import React from 'react';
import { Status } from '../../store/config/enums';
import variables from '../../styles/_variables.module.scss';
import CheckCircleIcon from '../Icons/CheckCircleIcon';
import ErrorIcon from '../Icons/ErrorIcon';

const useSnackbarStyles = makeStyles((theme) => ({
  snackbar: {
    margin: '3.2rem auto',
    [theme.breakpoints.down('sm')]: {
      width: '92%',
    },
  },
  message: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 500,
    width: '45rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  icon: {
    marginRight: 15,
    display: 'flex',
    alignItems: 'center',
  },
}));

interface CustomSnackbarProps {
  open: boolean;
  message: string;
  autoHide?: number;
  handleClose(event: React.SyntheticEvent<any>): void;
  type: number;
  onExited?: () => void;
}

function CustomSnackbar({ open, message, autoHide, handleClose, type, onExited }: CustomSnackbarProps) {
  const classes = useSnackbarStyles();
  const statusIcon = type === Status.SUCCESS ? <CheckCircleIcon /> : <ErrorIcon />;

  const statusStyle = {
    backgroundColor: type === Status.SUCCESS ? variables.lightestGreen : variables.lightRed,
    border: `1px solid ${type === Status.SUCCESS ? variables.green : variables.red}`,
    color: type === Status.SUCCESS ? variables.green : variables.red,
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHide ? autoHide : 2000}
      TransitionComponent={Slide}
      onClose={handleClose}
      classes={{ root: classes.snackbar }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onExited={onExited}
    >
      <SnackbarContent
        classes={{ message: classes.message }}
        style={statusStyle}
        message={
          <>
            <span className={classes.icon}>{statusIcon}</span>
            {message}
          </>
        }
      />
    </Snackbar>
  );
}

export default CustomSnackbar;
