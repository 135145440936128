import {
  ADD_BUSINESSES,
  BusinessDataTypes,
  CREATE_BUSINESS_FAILED,
  CREATE_BUSINESS_SUCCESS,
  CREATING_BUSINESS,
  FETCHING_BUSINESSES,
  FETCH_BUSINESSES_FAILED,
  GETTING_BUSINESS,
  GETTING_BUSINESS_SUB_BUSINESSES,
  GET_BUSINESS_FAILED,
  GET_BUSINESS_SUB_BUSINESSES_FAILED,
  GET_BUSINESS_SUB_BUSINESSES_SUCCESS,
  GET_BUSINESS_SUCCESS,
  LOGOUT,
  LogoutActionTypes,
  UPDATE_BUSINESS_FAILED,
  UPDATE_BUSINESS_SUCCESS,
  UPDATE_SECRET_FAILED,
  UPDATE_SECRET_SUCCESS,
  UPDATING_BUSINESS,
  UPDATING_SECRET,
} from '../config/ActionTypes';
import { BusinessState } from '../config/types';

export const initialState: BusinessState = {
  loadingBusinesses: false,
  businesses: null,
  businessesErrorMessage: null,
  creatingBusiness: false,
  createBusinessSuccess: false,
  createdBusiness: null,
  createBusinessErrorMessage: null,
  updatingBusiness: false,
  updateBusinessSuccess: false,
  updatedBusiness: null,
  updateBusinessErrorMessage: null,
  gettingBusiness: false,
  getBusinessSuccess: false,
  business: null,
  getBusinessErrorMessage: null,
  gettingSubBusinesses: false,
  subBusinesses: null,
  subBusinessesErrorMessage: null,
  updatingClientSecret: false,
  updateClientSecretSuccess: false,
  updateClientSecretErrorMessage: null,
};

export function businessReducer(
  state = initialState,
  action: BusinessDataTypes | LogoutActionTypes,
): BusinessState {
  switch (action.type) {
    case FETCHING_BUSINESSES:
      return {
        ...state,
        loadingBusinesses: true,
        businesses: null,
        businessesErrorMessage: null,
      };

    case ADD_BUSINESSES:
      return {
        ...state,
        loadingBusinesses: false,
        businesses: action.businesses,
        businessesErrorMessage: null,
      };

    case FETCH_BUSINESSES_FAILED:
      return {
        ...state,
        loadingBusinesses: false,
        businesses: null,
        businessesErrorMessage: action.error,
      };

    case CREATING_BUSINESS:
      return {
        ...state,
        creatingBusiness: true,
        createBusinessSuccess: false,
        createBusinessErrorMessage: null,
      };

    case CREATE_BUSINESS_SUCCESS:
      return {
        ...state,
        creatingBusiness: false,
        createBusinessSuccess: true,
        createdBusiness: action.business,
        createBusinessErrorMessage: null,
        business: action.business,
      };

    case CREATE_BUSINESS_FAILED:
      return {
        ...state,
        creatingBusiness: false,
        createBusinessSuccess: false,
        createBusinessErrorMessage: action.error,
        business: null,
      };

    case UPDATING_BUSINESS:
      return {
        ...state,
        updatingBusiness: true,
        updateBusinessSuccess: false,
        updateBusinessErrorMessage: null,
      };

    case UPDATE_BUSINESS_SUCCESS:
      return {
        ...state,
        updatingBusiness: false,
        updateBusinessSuccess: true,
        updatedBusiness: action.business,
        updateBusinessErrorMessage: null,
      };

    case UPDATE_BUSINESS_FAILED:
      return {
        ...state,
        updatingBusiness: false,
        updateBusinessSuccess: false,
        updateBusinessErrorMessage: action.error,
      };

    case GETTING_BUSINESS:
      return {
        ...state,
        gettingBusiness: true,
        getBusinessSuccess: false,
        business: null,
        getBusinessErrorMessage: null,
      };

    case GET_BUSINESS_SUCCESS:
      return {
        ...state,
        gettingBusiness: false,
        getBusinessSuccess: true,
        business: action.business,
        getBusinessErrorMessage: null,
      };

    case GET_BUSINESS_FAILED:
      return {
        ...state,
        gettingBusiness: false,
        getBusinessSuccess: false,
        business: null,
        getBusinessErrorMessage: action.error,
      };

    case GETTING_BUSINESS_SUB_BUSINESSES:
      return {
        ...state,
        gettingSubBusinesses: true,
        subBusinesses: null,
        subBusinessesErrorMessage: null,
      };

    case GET_BUSINESS_SUB_BUSINESSES_SUCCESS:
      return {
        ...state,
        gettingSubBusinesses: false,
        subBusinesses: action.subBusinesses,
        subBusinessesErrorMessage: null,
      };

    case GET_BUSINESS_SUB_BUSINESSES_FAILED:
      return {
        ...state,
        gettingSubBusinesses: false,
        subBusinesses: null,
        subBusinessesErrorMessage: action.error,
      };

    case UPDATING_SECRET:
      return {
        ...state,
        updatingClientSecret: true,
        updateClientSecretSuccess: false,
        updateClientSecretErrorMessage: null,
      };

    case UPDATE_SECRET_SUCCESS:
      return {
        ...state,
        updatingClientSecret: false,
        updateClientSecretSuccess: true,
        updateClientSecretErrorMessage: null,
      };

    case UPDATE_SECRET_FAILED:
      return {
        ...state,
        updatingClientSecret: false,
        updateClientSecretSuccess: false,
        updateClientSecretErrorMessage: action.error,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
