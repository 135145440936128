import { Button, CircularProgress, Divider, Theme, createStyles, makeStyles } from '@material-ui/core';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { numberHelper } from '../../../helpers/numberHelper';
import { Currency } from '../../../store/config/enums';
import { LinkIssuer } from '../../../store/config/types';
import styles from '../../../styles/_variables.module.scss';
import { LongArrowLeft } from '../../Icons/LongArrowLeft';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    floatingButtons: {
      position: 'fixed',
      padding: '1rem 2rem',
      bottom: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      justifyContent: 'flex-end',
      backgroundColor: 'white',
      width: '100%',
      boxShadow: styles.boxShadowFloatingButtons,
    },
    descriptionRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& p': {
        color: styles.slateBlue,
        fontSize: '0.75rem',
        fontWeight: Number(styles.normalTextWeight),
        margin: 0,
      },
      '&:last-child': {
        margin: '1.5625rem 0 0 0',
      },
    },
    total: {
      color: styles.secondaryMain,
      fontSize: '1rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      margin: 0,
      '& span': {
        fontSize: '0.75rem',
        fontWeight: Number(styles.normalTextWeight),
        color: styles.slateBlue,
      },
    },
    totalAmount: {
      color: styles.secondaryMain,
      fontSize: '1.375rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      margin: 0,
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      gap: '1rem',
      width: '100%',
      justifyContent: 'flex-end',
    },
    backButton: {
      width: '25%',
      [theme.breakpoints.down('md')]: {
        maxWidth: '3.75rem',
        minWidth: '3.75rem',
      },
    },
  }),
);

interface FloatingButtonsProps {
  step: number;
  setStep: (step: number) => void;
  selectedIssuer: LinkIssuer | null;
  amount: number;
  currency: Currency;
  vatRate?: number;
  isOntheFly?: boolean;
  formId?: string;
  submitting?: boolean;
}

function FloatingButtons({
  step,
  setStep,
  selectedIssuer,
  amount,
  currency,
  vatRate,
  isOntheFly = false,
  formId,
  submitting,
}: FloatingButtonsProps) {
  const classes = useStyles();

  const handleClickPrimaryButton = () => {
    if (!formId) setStep(2);
  };

  return (
    <div className={classes.floatingButtons}>
      {(!isOntheFly || (isOntheFly && step === 2 && amount)) && (
        <>
          <div className={classes.descriptionRow}>
            <h2 className={classes.total}>Total {vatRate && vatRate > 0 ? <span>IVA inc.</span> : ''}</h2>
            <h3 className={classes.totalAmount}>
              {currencyHelper.getCurrencyToken(currency)}
              {numberHelper.getNumberWithDots(amount)}
            </h3>
          </div>
          <Divider />
        </>
      )}
      <div className={classes.buttons}>
        {step === 2 && (
          <Button
            onClick={() => setStep(1)}
            variant="outlined"
            color="secondary"
            className={classes.backButton}
          >
            <LongArrowLeft />
          </Button>
        )}
        <Button
          onClick={handleClickPrimaryButton}
          variant="contained"
          color="primary"
          disabled={!selectedIssuer || !amount || submitting}
          fullWidth
          type={formId ? 'submit' : 'button'}
          form={formId}
        >
          {submitting ? <CircularProgress size={28} color="inherit" /> : 'Continuar'}
        </Button>
      </div>
    </div>
  );
}

export default FloatingButtons;
