import { currencyHelper } from '../../helpers/currencyHelper';
import { numberHelper } from '../../helpers/numberHelper';
import { paymentStatusEnumHelper } from '../../helpers/paymentStatusEnumHelper';
import { PaymentStatusEnum } from '../../store/config/enums';
import { Amount } from '../../store/config/types';
import { StatusPill } from '../StatusPill';
import DetailRow from './DetailRow';

interface SplitPaymentDetailProps {
  amount: Amount;
  taxedAmount: Amount;
  status: PaymentStatusEnum;
  name: string;
  rightWidthClass: string;
  amountDetailClass: string;
  totalAmountClass: string;
  titleClass: string;
  order: number;
}

function SplitPaymentDetail({
  amount,
  taxedAmount,
  status,
  name,
  rightWidthClass,
  totalAmountClass,
  amountDetailClass,
  titleClass,
  order,
}: SplitPaymentDetailProps) {
  const splitPaymentCurrency = currencyHelper.getCurrencyToken(amount.currency);
  const splitPaymentTaxedAmountWithDots = numberHelper.getNumberWithDots(taxedAmount.value);
  const splitPaymentIVA = numberHelper.getNumberWithDots(amount.value - taxedAmount.value);
  const splitPaymentAmountWithDots = numberHelper.getNumberWithDots(amount.value);

  return (
    <>
      <div className={titleClass}>{`Destino ${order}`} </div>
      <DetailRow
        title="Estado"
        detail={
          <StatusPill
            label={paymentStatusEnumHelper.getStatusText(status)}
            color={paymentStatusEnumHelper.getStatusColorVariant(status)}
          />
        }
        isState
      />
      <DetailRow title="Empresa" detail={name || 'No especificado'} />
      <DetailRow
        title="Importe"
        detail={
          <div className={rightWidthClass}>
            <p className={totalAmountClass}>{`${splitPaymentCurrency} ${splitPaymentAmountWithDots}`}</p>
            <p
              className={amountDetailClass}
            >{`${splitPaymentCurrency} ${splitPaymentTaxedAmountWithDots} + ${splitPaymentCurrency} ${splitPaymentIVA} (IVA)`}</p>
          </div>
        }
      />
    </>
  );
}

export default SplitPaymentDetail;
