import { IconProps } from '../../../data/icon.type';
import colors from '../../../styles/_variables.module.scss';

function SplitIcon({ color, className }: IconProps) {
  color = color ?? colors.slateBlue;
  className = className ?? '';
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6965_7388)">
        <path
          d="M12 4.5C12 3.11875 10.8813 2 9.5 2C8.11875 2 7 3.11875 7 4.5C7 5.725 7.88125 6.74375 9.04688 6.95937C9.02188 7.49687 8.89062 7.90938 8.65 8.20938C8.10312 8.89063 6.99063 8.99687 5.81563 9.10625C4.93438 9.1875 4.02187 9.275 3.30313 9.63437C3.19688 9.6875 3.09375 9.74687 3 9.8125V4.95C4.14062 4.71875 5 3.70938 5 2.5C5 1.11875 3.88125 0 2.5 0C1.11875 0 0 1.11875 0 2.5C0 3.70938 0.859375 4.71875 2 4.95V11.05C0.859375 11.2812 0 12.2906 0 13.5C0 14.8813 1.11875 16 2.5 16C3.88125 16 5 14.8813 5 13.5C5 12.3469 4.22188 11.3781 3.15937 11.0875C3.31562 10.7875 3.54375 10.6313 3.75313 10.525C4.3 10.25 5.08125 10.175 5.90625 10.0969C7.2375 9.97188 8.61563 9.84375 9.42813 8.83125C9.81563 8.34688 10.0219 7.72188 10.0469 6.93437C11.1656 6.6875 12 5.69063 12 4.5ZM1 2.5C1 1.67188 1.67188 1 2.5 1C3.32813 1 4 1.67188 4 2.5C4 3.32812 3.32813 4 2.5 4C1.67188 4 1 3.32812 1 2.5ZM4 13.5C4 14.3281 3.32813 15 2.5 15C1.67188 15 1 14.3281 1 13.5C1 12.675 1.66875 12.0031 2.49375 12H2.5125C3.33438 12.0063 4 12.6781 4 13.5ZM9.86875 5.95312L9.5 6C8.67188 6 8 5.32812 8 4.5C8 3.67188 8.67188 3 9.5 3C10.3281 3 11 3.67188 11 4.5C11 5.2 10.5188 5.7875 9.86875 5.95312Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6965_7388">
          <rect width="12" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SplitIcon;
