import { InputAdornment, TextField, TextFieldProps, Typography, makeStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { FieldProps } from 'formik';
import React, { ChangeEvent } from 'react';
import variables from '../../../styles/_variables.module.scss';

const useTextFieldStyles = makeStyles({
  root: {
    width: '100%',
  },
  icon: {
    cursor: 'pointer',
  },
  error: {
    color: variables.red,
    fontSize: 14,
  },
});

interface CustomProps {
  startAdornment?: string;
  noLeadingZeros: boolean;
}

type FormTextFieldProps = CustomProps & FieldProps & React.HTMLProps<HTMLInputElement> & TextFieldProps;

function FormTextField({ startAdornment, noLeadingZeros, field, form, ...props }: FormTextFieldProps) {
  const classes = useTextFieldStyles();
  const hasError = (form.touched[field.name] && form.errors[field.name]) !== undefined;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    // Only numbers and one decimal separator allowed
    let sanitizedValue = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');

    if (props.type === 'tel') {
      // Limit input to two decimal places
      if (sanitizedValue.includes('.')) {
        const parts = sanitizedValue.split('.');
        if (parts[1] && parts[1].length > 2) {
          parts[1] = parts[1].substring(0, 2);
        }
        sanitizedValue = parts[0] + '.' + parts[1];
      }

      // Remove leading zeros
      if (sanitizedValue.startsWith('0')) {
        sanitizedValue = sanitizedValue.replace(/^0+([1-9])/g, '$1').replace(/^0+(\.)/, '0$1');
      }

      event.target.value = sanitizedValue;
    }

    field.onChange(event);

    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <>
      <TextField
        margin={props.margin ?? 'normal'}
        variant="outlined"
        className={props.className}
        error={hasError}
        classes={{ root: classes.root }}
        inputProps={{
          maxLength: props.max,
        }}
        InputProps={{
          className: props.className,
          startAdornment: startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : null,
          endAdornment: hasError ? (
            <InputAdornment position="end">
              <ErrorIcon color="error" classes={{ root: classes.icon }} />
            </InputAdornment>
          ) : null,
        }}
        {...field}
        onChange={handleChange}
        type={props.type}
        label={props.placeholder}
        disabled={props.disabled}
        multiline={props.multiline}
        rows={props.rows}
        style={props.style}
      />
      {hasError && form.errors[field.name] && (
        <Typography className={classes.error}>{form.errors[field.name]!.toString()}</Typography>
      )}
    </>
  );
}

export default FormTextField;
