import { Field } from 'formik';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { LinkValues } from '../../../store/config/types';
import { FormTextField } from '../../forms/FormTextField';

interface AmountInputProps {
  values: LinkValues;
  informationLabelClass: string;
}

function AmountInput({ values, informationLabelClass }: AmountInputProps) {
  const currencyToken = currencyHelper.getCurrencyToken(values.currency);
  const vatAsDecimal = values.vatRate / 100;
  const subTotal = values.value / (1 + vatAsDecimal);
  const vatAmount = values.value - values.value / (1 + vatAsDecimal);

  return (
    <>
      <div className={informationLabelClass}>
        <p>Importe Total</p>
        <i>
          Subtotal: {currencyToken} {subTotal.toFixed(2)} | IVA: {currencyToken} {vatAmount.toFixed(2)}
        </i>
      </div>
      <Field name="value" component={FormTextField} type="tel" noLeadingZeros />
    </>
  );
}

export default AmountInput;
