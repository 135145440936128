import { CircularProgress, Divider, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '../../assets/addIcon';
import DeleteIcon from '../../assets/deleteIcon';
import { useFetchLinksOnUpdate } from '../../hooks/useFetchLinksOnUpdate';
import { deletePaymentMethod, fetchPaymentMethods } from '../../store/action_creators/paymentMethods.actions';
import { Status } from '../../store/config/enums';
import { PaymentMethod, RootState } from '../../store/config/types';
import { CustomSnackbar } from '../CustomSnackbar';
import { DeleteDialog } from '../dialogs/DeleteDialog';
import { PaymentMethodDialog } from '../dialogs/PaymentMethodDialog';
import SinglePaymentMethodDeleteTooltip from './SingleDeletePaymentMethodDeleteTooltip';

function PaymentMethods() {
  const dispatch = useDispatch();
  const { auth, paymentMethods } = useSelector((state: RootState) => state);
  const [open, setOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deletingPaymentMethod, setDeletingPaymentMethod] = useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null);
  const [paymentMethodToDelete, setPaymentMethodToDelete] = useState<PaymentMethod | null>(null);
  const fetchLinksOnUpdate = useFetchLinksOnUpdate();

  useEffect(() => {
    if (
      auth.account &&
      !paymentMethods.loadingPaymentMethods &&
      paymentMethods.paymentMethods === null &&
      paymentMethods.paymentMethodsErrorMessage === null
    ) {
      dispatch(fetchPaymentMethods(auth.account.business.id!));
    }
  }, [
    auth.account,
    paymentMethods.loadingPaymentMethods,
    paymentMethods.paymentMethods,
    paymentMethods.paymentMethodsErrorMessage,
    dispatch,
  ]);

  const createPaymentMethod = () => {
    setSelectedPaymentMethod(null);
    setOpen(true);
  };

  const updatePaymentMethod = (paymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    setOpen(true);
  };

  const beginDeletePaymentMethod = (paymentMethod: PaymentMethod) => {
    setPaymentMethodToDelete(paymentMethod);
    setDeleteOpen(true);
  };

  const confirmDeletePaymentMethod = () => {
    setDeletingPaymentMethod(true);

    if (paymentMethodToDelete && auth.account && paymentMethods.paymentMethods)
      dispatch(
        deletePaymentMethod(
          auth.account.business.id!,
          paymentMethodToDelete?.issuerId,
          paymentMethods.paymentMethods.length === 1,
        ),
      );
  };

  const closeSnack = () => {
    setDeletingPaymentMethod(false);

    if (paymentMethods.deletePaymentMethodSuccess) {
      fetchLinksOnUpdate();
    }

    setDeleteOpen(false);
  };

  if (paymentMethods.loadingPaymentMethods) return <CircularProgress />;

  return (
    <>
      <div className="payment-methods-list">
        <div className="new-payment-method" onClick={createPaymentMethod}>
          <AddIcon />
          <Typography variant="subtitle2">Nuevo medio de pago</Typography>
        </div>
        {paymentMethods.paymentMethods &&
          paymentMethods.paymentMethods.map((paymentMethod, index) => {
            return (
              <div className="payment-method" onClick={() => updatePaymentMethod(paymentMethod)} key={index}>
                <div className="trash">
                  {paymentMethods.paymentMethods && paymentMethods.paymentMethods.length === 1 ? (
                    <SinglePaymentMethodDeleteTooltip />
                  ) : (
                    <Tooltip title="Eliminar" placement="top" enterDelay={500}>
                      <span
                        onClick={(e: React.SyntheticEvent<any>) => {
                          e.stopPropagation();
                          beginDeletePaymentMethod(paymentMethod);
                        }}
                      >
                        <DeleteIcon />
                      </span>
                    </Tooltip>
                  )}
                </div>
                <img src={paymentMethod.issuerImageUrl} alt="logo" />
                <Divider orientation="horizontal" className="divider" />
                <div className="number">
                  <Typography noWrap className="payment-method-text">
                    {paymentMethod.issuerName}
                  </Typography>
                </div>
              </div>
            );
          })}
      </div>
      <PaymentMethodDialog open={open} setOpen={setOpen} paymentMethod={selectedPaymentMethod} />
      <DeleteDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        message={`¿Seguro que deseas eliminar el medio de pago ${paymentMethodToDelete?.issuerName}?`}
        deleteAction={confirmDeletePaymentMethod}
        deleteDisabled={deletingPaymentMethod}
      />
      <CustomSnackbar
        open={
          deletingPaymentMethod &&
          (paymentMethods.deletePaymentMethodSuccess ||
            paymentMethods.deletePaymentMethodErrorMessage !== null)
        }
        message={
          paymentMethods.deletePaymentMethodSuccess
            ? 'Se borró el medio de pago correctamente'
            : 'Ocurrió un error al borrar el medio de pago'
        }
        handleClose={closeSnack}
        type={paymentMethods.deletePaymentMethodSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </>
  );
}

export default PaymentMethods;
