import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MultiUseLinkIcon from '../../assets/MultiUseLinkIcon';
import OneTimeLinkIcon from '../../assets/OneTimeLinkIcon';
import { AuthState, RootState } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      height: '100%',
      gridTemplateRows: '1fr auto',
      gridTemplateColumns: '1fr',
    },
    texts: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& h6': {
        textAlign: 'center',
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: '2rem',
      },
    },
    buttonsDiv: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: '0.75rem',
      alignSelf: 'flex-end',
    },
    button: {
      fontWeight: Number(styles.normalTextWeight),
      width: '100%',
      '& .MuiButton-startIcon': {
        color: styles.white,
      },
      '&:hover': {
        '& path': {
          fill: styles.primary,
        },
      },
    },
  }),
);

function EmptyLinksMessage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const auth: AuthState = useSelector((state: RootState) => state.auth);
  const noLinksEverCreated = !auth.account?.business?.hasLinks;

  return (
    <div className={`empty-message ${classes.container}`}>
      <div className={classes.container}>
        <div className={classes.texts}>
          <Typography variant="h6" className="title">
            {noLinksEverCreated ? '¡Bienvenido a Plexo Links! ' : 'Aún no tienes links de pago creados'}
          </Typography>
          <Typography variant="h6" className="message">
            {`${
              noLinksEverCreated ? 'Aún no tienes links de pago creados.' : ''
            } Podrás visualizarlos aquí una vez que los crees.`}
          </Typography>
        </div>
        <div className={classes.buttonsDiv}>
          <Button
            disableElevation
            className={classes.button}
            color="primary"
            variant="contained"
            startIcon={<OneTimeLinkIcon color={styles.white} transparent />}
            onClick={() => navigate('/one-use-link')}
          >
            Crear link de 1 uso
          </Button>
          <Button
            disableElevation
            className={classes.button}
            color="primary"
            variant="contained"
            startIcon={<MultiUseLinkIcon color={styles.white} transparent />}
            onClick={() => navigate('/permanent-link')}
          >
            Crear link multiuso
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EmptyLinksMessage;
