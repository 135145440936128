import { Divider, IconButton, Paper, Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import ShareIcon from '../../assets/ShareIcon';
import { currencyHelper } from '../../helpers/currencyHelper';
import { linkHelper } from '../../helpers/linkHelper';
import { linkTypeEnumHelper } from '../../helpers/linkTypeEnumHelper';
import { numberHelper } from '../../helpers/numberHelper';
import { paymentTypeHelper } from '../../helpers/paymentTypeHelper';
import { Link } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import LinkToShareTooltip from '../LinkCard/LinkToShareTooltip';
import { ShareLinkDialog } from '../dialogs/ShareLinkDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '0.5rem 0.875rem',
      boxSizing: 'border-box',
      border: `0.5px solid ${styles.dividerHorizontal}`,
      boxShadow: 'none',
    },
    rowContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      boxSizing: 'border-box',
      gap: '0.625rem',
    },
    detailInfo: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.625rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& svg': {
        flexShrink: 0,
        width: 'auto',
        height: 'auto',
      },
      '& p': {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    amountInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '& p': {
        textAlign: 'right',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontWeight: styles.boldTextWeight,
      },
    },
    descriptionRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    smallIconButton: {
      fontSize: '1rem',
      padding: 0,
      '&:hover': { backgroundColor: 'transparent' },
    },
    divider: {
      margin: '0 0.5rem',
      alignSelf: 'center',
      height: '1.6rem',
    },
  }),
);

interface LinkListRowProps {
  link: Link;
}

function LinkListRow({ link }: LinkListRowProps) {
  const classes = useStyles();
  const currencyToken = currencyHelper.getCurrencyToken(link.amount.currency);
  const totalAmount = link.amount.value + (link.splitLinkDto?.amount.value || 0);
  const amountWithDots = numberHelper.getNumberWithDots(totalAmount);
  const [shareOpen, setShareOpen] = useState<boolean>(false);
  const isLinkExpired = link.validUntil ? linkHelper.verifyValidation(link.validUntil) : undefined;
  const noPaymentMethods = linkHelper.verifyPaymentMethods(link.installmentsDto ?? []);

  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShareOpen(true);
  };

  return (
    <Paper className={classes.container}>
      <div className={classes.rowContainer}>
        <div className={classes.descriptionRow}>
          {linkTypeEnumHelper.getIcon(link.linkType)}
          <Divider className={classes.divider} orientation="vertical" />
          <span>{paymentTypeHelper.paymentTypesTitle[link.paymentType]}</span>
        </div>
        <div className={classes.amountInfo}>
          <p>{`${currencyToken} ${amountWithDots}`}</p>
        </div>
      </div>
      <Divider />
      <div className={classes.descriptionRow}>
        <p>{link.description ? link.description : <i>Sin descripción</i>}</p>
        {isLinkExpired || noPaymentMethods ? (
          <LinkToShareTooltip reason={isLinkExpired ? 'expired' : 'noPaymentMethods'} />
        ) : (
          <Tooltip title="Compartir" placement="top" enterDelay={500}>
            <IconButton
              onClick={handleShareClick}
              size="small"
              classes={{ sizeSmall: classes.smallIconButton }}
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <ShareLinkDialog open={shareOpen} setOpen={setShareOpen} link={link} />
    </Paper>
  );
}

export default LinkListRow;
