import { Link, LinkIssuer, LinkToPay } from '../store/config/types';

function verifyValidation(date: Date | string): boolean {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  const now = new Date();
  return dateObj < now;
}

function verifyPaymentMethods(linkIssuers: LinkIssuer[] | undefined): boolean {
  return linkIssuers?.length === 0;
}

function getTotalLinkAmount(link?: Link | LinkToPay): number {
  if (!link) return 0;

  return +link.amount.value + (link.splitLinkDto?.amount?.value || 0);
}

export const linkHelper = { verifyValidation, verifyPaymentMethods, getTotalLinkAmount };
