import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLinks, fetchOneUseLinks } from '../store/action_creators/links.actions';
import { DateSpan, LinkTypeEnum, PaymentsColumnSortEnum, SortEnum } from '../store/config/enums';
import { RootState } from '../store/config/types';
import { initialState as initialLinks } from '../store/reducers/links';

export const useFetchLinksOnUpdate = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const links = useSelector((state: RootState) => state.links);

  const fetchLinksOnUpdate = useCallback(() => {
    const filters = {
      dateSpan: DateSpan.LAST7DAYS,
      columnSort: PaymentsColumnSortEnum.Date,
      sortDirection: SortEnum.DESC,
    };

    dispatch(
      fetchOneUseLinks(
        links.oneUseLinksFilters || initialLinks.oneUseLinksFilters,
        links.oneUseCurrentPage || 1,
        10,
        auth.account!.business.id!,
        LinkTypeEnum.ONETIME,
        true,
      ),
    );
    dispatch(
      fetchLinks(
        links.linksFilters || initialLinks.linksFilters,
        links.currentPage || 1,
        10,
        auth.account!.business.id!,
        LinkTypeEnum.PERMANENT,
        true,
      ),
    );
    dispatch(fetchLinks(filters, 1, 7, auth.account!.business.id!, LinkTypeEnum.ALL, true, true, true));
  }, [dispatch, auth.account, links]);

  return fetchLinksOnUpdate;
};
