import { Divider, IconButton, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewIcon } from '../../components/Icons/ViewIcon';
import { StatusPill } from '../../components/StatusPill';
import { currencyHelper } from '../../helpers/currencyHelper';
import { dateHelper } from '../../helpers/dateHelper';
import { numberHelper } from '../../helpers/numberHelper';
import { paymentStatusEnumHelper } from '../../helpers/paymentStatusEnumHelper';
import { Payment } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      boxSizing: 'border-box',
      margin: '0.6875rem 0',
      '& p': {
        margin: 0,
      },
    },
    detailInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& svg': {
        flexShrink: 0,
        width: 'auto',
        height: 'auto',
      },
      '& p': {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    amountInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexGrow: 1,
      '& p': {
        textAlign: 'right',
        minWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontWeight: styles.boldTextWeight,
      },
    },
    divider: {
      margin: '0 0.5rem',
      alignSelf: 'center',
      height: '1.6rem',
    },
    button: {
      padding: '0',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    amountSection: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    amountText: {
      color: styles.darkBlue,
      fontWeight: 500,
    },
    dateAndDescriptionContainer: {
      width: '70%',
    },
    description: {
      width: '100%',
      color: styles.iconColor,
      fontSize: '0.75rem',
      textWrap: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

interface PaymentCardDesktopProps {
  payment: Payment;
  isLast?: boolean;
}

function PaymentCardDesktop({ payment }: PaymentCardDesktopProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const currencyToken = currencyHelper.getCurrencyToken(payment.amount.currency);
  const splitPaymentTotal = payment.splitPayment && payment.splitPayment.amount.value + payment.amount.value;
  const amountWithDots = numberHelper.getNumberWithDots(splitPaymentTotal || payment.amount.value);

  return (
    <div className={classes.container}>
      <div className={classes.detailInfo}>
        <StatusPill
          label={paymentStatusEnumHelper.getStatusText(payment.status)}
          color={paymentStatusEnumHelper.getStatusColorVariant(payment.status)}
          isCard
        />
        <Divider className={classes.divider} orientation="vertical" />
        <div className={classes.dateAndDescriptionContainer}>
          <div>{dateHelper.getDashboardPaymentDate(payment.date)}</div>
          <div className={classes.description}>{payment.description || <i>Sin descripción</i>}</div>
        </div>
      </div>
      <div className={classes.amountInfo}>
        <p>{`${currencyToken} ${amountWithDots}`}</p>
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton
          className={classes.button}
          disableRipple
          onClick={() =>
            navigate(`/payments/${payment.id}`, {
              state: { from: location.pathname },
            })
          }
        >
          <ViewIcon />
        </IconButton>
      </div>
      <Divider />
    </div>
  );
}

export default PaymentCardDesktop;
