import { Button, CircularProgress } from '@material-ui/core';
import { PaymentMethod } from '../../../store/config/types';

interface SubmitButtonProps {
  isRegisterStep: boolean;
  creatingPaymentMethod: boolean;
  updatingPaymentMethod: boolean;
  paymentMethod: PaymentMethod | null;
  disabled?: boolean;
}

function SubmitButton({
  isRegisterStep,
  creatingPaymentMethod,
  updatingPaymentMethod,
  paymentMethod,
  disabled,
}: SubmitButtonProps) {
  return (
    <div className="button-row-field">
      <Button
        type="submit"
        color="primary"
        variant="contained"
        form="payment-method-form"
        fullWidth={!isRegisterStep}
        disabled={creatingPaymentMethod || updatingPaymentMethod || disabled}
      >
        {creatingPaymentMethod || updatingPaymentMethod ? (
          <CircularProgress size={28} color="inherit" />
        ) : paymentMethod ? (
          'Guardar cambios'
        ) : (
          'Agregar medio de pago'
        )}
      </Button>
    </div>
  );
}

export default SubmitButton;
