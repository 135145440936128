import { Tooltip, TooltipProps } from '@material-ui/core';

interface ConditionalTooltipProps extends Omit<TooltipProps, 'children'> {
  showTooltip: boolean;
  children: TooltipProps['children'];
}

function ConditionalTooltip({ showTooltip, children, ...props }: ConditionalTooltipProps) {
  return showTooltip ? (
    <Tooltip enterTouchDelay={0} leaveTouchDelay={4000} interactive {...props}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
}

export default ConditionalTooltip;
