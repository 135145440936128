import { createMuiTheme } from '@material-ui/core/styles';
import styles from './_variables.module.scss';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: styles.primary,
      light: '#E8F0F8',
    },
    secondary: {
      main: styles.secondary,
    },
    error: {
      main: '#FF0000',
      light: 'rgb(from #FF0000 r g b / 10%',
      dark: '#FF0000',
    },
    warning: {
      main: '#ffc400',
      light: '#fff9e5',
      dark: '#ffc400',
    },
    success: {
      main: '#1FD286',
      light: 'rgb(from #1FD286 r g b / 10%',
      dark: '#039054',
    },
    info: {
      main: styles.slateBlue,
      light: styles.slateBlueLight,
      dark: styles.slateBlue,
    },
    background: {
      default: '#f6f6f6',
    },
  },
  typography: {
    fontFamily: `"Rubik", sans-serif`,
    h1: {
      fontSize: '3.75rem',
    },
    h2: {
      fontSize: '3rem',
    },
    h3: {
      fontSize: '2.125rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.25rem',
    },
    h6: {
      fontSize: '1.0625rem',
    },
    subtitle1: {
      color: styles.slateBlue,
      fontSize: '0.875rem',
      fontWeight: Number(styles.normalTextWeight),
    },
    subtitle2: {
      color: styles.slateBlue,
      fontSize: '0.9375rem',
      fontWeight: Number(styles.semiBoldTextWeight),
    },
  },
  breakpoints: {
    values: {
      xs: 300,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 6,
        textTransform: 'none',
        padding: '0.5rem 1rem',
        fontSize: 16,
        fontWeight: Number(styles.normalTextWeight),
        minWidth: '9.375rem',
        minHeight: '2.5rem',
      },
      outlined: {
        padding: '0.5rem 1rem',
      },
      contained: {
        boxShadow: 'none',
      },
      containedPrimary: {
        color: styles.white,
        border: `1px solid ${styles.primary}`,
        boxShadow: 'none !important',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            color: styles.primary,
            boxShadow: 'none !important',
            backgroundColor: styles.white,
            border: `1px solid ${styles.primary}`,
          },
        },
        '&.Mui-disabled': {
          backgroundColor: `${styles.primaryDisabled} !important`,
          boxShadow: 'none',
          borderColor: 'transparent !important',
          color: `${styles.white} !important`,
        },
      },
      outlinedSecondary: {
        color: styles.textButtonSecondary,
        borderColor: styles.borderButtonSecondary,
        fontWeight: Number(styles.normalTextWeight),
        backgroundColor: styles.white,
        '&:hover': {
          borderColor: styles.borderButtonSecondary,
          backgroundColor: styles.borderButtonSecondary,
          '@media (max-width: 768px)': {
            backgroundColor: `${styles.white} !important`,
          },
        },
        '&.Mui-disabled': {
          color: styles.textButtonSecondary,
          borderColor: styles.borderButtonSecondary,
          fontWeight: Number(styles.normalTextWeight),
          backgroundColor: styles.white,
          opacity: 0.5,
        },
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: '4px',
        '&:hover': {
          '& svg': {
            '& rect': {
              fill: styles.borderButtonSecondary,
            },
          },
        },
      },
    },
    MuiChip: {
      root: {
        color: styles.slateBlue,
        border: `1px solid ${styles.borderButtonSecondary}`,
        width: '2.75rem',
        height: '2.75rem',
        borderRadius: '0.25rem',
        backgroundColor: styles.white,
      },
      clickable: {
        '&:hover': {
          backgroundColor: styles.borderButtonSecondary,
        },
        '&:focus': {
          backgroundColor: styles.borderButtonSecondary,
        },
      },
      clickableColorPrimary: {
        borderColor: styles.primary,
      },
      colorPrimary: {
        color: styles.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        padding: '0.375rem 1.25rem',
        '@media (min-width: 768px)': {
          minWidth: 0,
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(112, 112, 112, 0.9)',
      },
    },
    MuiAppBar: {
      positionSticky: {
        top: 0,
      },
      colorPrimary: {
        backgroundColor: styles.white,
        boxShadow: styles.headerShadow,
      },
      colorSecondary: {
        backgroundColor: styles.secondary,
        boxShadow: styles.publicHeaderShadow,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: styles.boxShadow,
      },
      elevation2: {
        padding: '2.6rem 2rem 2rem',
        boxShadow: styles.boxShadow,
        width: '100%',
        marginTop: '1rem',
      },
      elevation3: {
        padding: '0.8rem 1.6rem',
        boxShadow: styles.boxShadow,
        width: '100%',
        marginTop: '1rem',
      },
      elevation8: {
        boxShadow: styles.boxShadowDropdownMenu,
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: styles.borderButtonSecondary,
        },
        '&:hover $notchedOutline': {
          borderColor: styles.borderButtonSecondary,
        },
        '&:focus $notchedOutline': {
          borderColor: styles.borderButtonSecondary,
        },
        color: styles.slateBlue,
        '&.Mui-disabled': {
          backgroundColor: styles.lightGrey,
          color: styles.iconColor,
          '& $notchedOutline': {
            borderColor: styles.lightGrey,
          },
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: styles.slateBlue,
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: 0,
      },
    },
    MuiSelect: {
      icon: {
        color: styles.slateBlue,
      },
    },
    MuiTableContainer: {
      root: {
        padding: '1.5rem',
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: styles.white,
        transition: 'background-color 500ms',
        border: 'none',
        '&.MuiTableRow-hover:hover': {
          backgroundColor: 'unset',
          cursor: 'default',
        },
        '&.MuiTableRow-head': {
          '&:hover': {
            backgroundColor: styles.greyTableHead,
          },
        },
      },
      head: {
        backgroundColor: styles.greyTableHead,
        '&:hover': {
          backgroundColor: styles.grayTableHead,
          cursor: 'default',
        },
      },
    },
    MuiTableCell: {
      root: {
        textAlign: 'center',
        border: 'none',
        fontSize: '0.875rem',
        borderBottom: 'none',
        padding: '0.25rem',
        wordBreak: 'break-word',
        maxWidth: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      head: {
        color: styles.slateBlue,
        fontWeight: 'bold',
        marginLeft: '1rem',
        whiteSpace: 'nowrap',
        minWidth: '6rem',
      },
      body: {
        color: styles.black,
      },
    },
    MuiListItem: {
      root: {
        '&.Mui-selected': {
          backgroundColor: styles.borderButtonSecondary,
        },
      },
      button: {
        '&:hover': {
          backgroundColor: styles.borderButtonSecondary,
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        transform: 'translateX(1rem)',
        '&.MuiTableSortLabel-active': {
          color: styles.slateBlue,
        },
      },
    },
    MuiLink: {
      underlineHover: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.75rem',
      },
      touch: {
        fontSize: '0.75rem',
        padding: '0.5rem',
      },
    },
    MuiDivider: {
      root: {
        height: '1px',
        backgroundColor: styles.dividerHorizontal,
      },
    },
  },
  props: {
    MuiTableRow: {
      hover: true,
    },
    MuiTableCell: {
      align: 'center',
    },
  },
});
