import {
  ADD_BUSINESS_ISSUERS,
  ADD_ISSUERS,
  ADD_PAYMENT_METHODS,
  CREATE_PAYMENT_METHOD_FAILED,
  CREATE_PAYMENT_METHOD_SUCCESS,
  CREATING_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_FAILED,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETING_PAYMENT_METHOD,
  DESIRED_ISSUERS_FAILED,
  DESIRED_ISSUERS_SUCCESS,
  FETCHING_BUSINESS_ISSUERS,
  FETCHING_INSTALLMENTS,
  FETCHING_ISSUERS,
  FETCHING_PAYMENT_METHODS,
  FETCH_BUSINESS_ISSUERS_FAILED,
  FETCH_INSTALLMENTS_FAILED,
  FETCH_INSTALLMENTS_SUCCESS,
  FETCH_ISSUERS_FAILED,
  FETCH_PAYMENT_METHODS_FAILED,
  LOGOUT,
  LogoutActionTypes,
  PaymentMethodsDataTypes,
  SENDING_DESIRED_ISSUERS,
  UPDATE_PAYMENT_METHOD_FAILED,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATING_PAYMENT_METHOD,
} from '../config/ActionTypes';
import { PaymentMethod, PaymentMethodsState } from '../config/types';

export const initialState: PaymentMethodsState = {
  loadingPaymentMethods: false,
  paymentMethods: null,
  paymentMethodsErrorMessage: null,
  creatingPaymentMethod: false,
  createPaymentMethodSuccess: false,
  createPaymentMethodErrorMessage: null,
  updatingPaymentMethod: false,
  updatePaymentMethodSuccess: false,
  updatePaymentMethodErrorMessage: null,
  deletingPaymentMethod: false,
  deletePaymentMethodSuccess: false,
  deletePaymentMethodErrorMessage: null,
  loadingIssuers: false,
  issuers: null,
  issuersErrorMessage: null,
  businessIssuers: null,
  loadingBusinessIssuers: false,
  businessIssuersErrorMessage: null,
  sendingDesiredIssuers: false,
  desiredIssuersSuccess: false,
  desiredIssuersErrorMessage: null,
  possibleInstallments: null,
  fetchingPossibleInstallments: false,
  fetchInstallmentsError: null,
};

export function paymentMethodsReducer(
  state = initialState,
  action: PaymentMethodsDataTypes | LogoutActionTypes,
): PaymentMethodsState {
  switch (action.type) {
    case FETCHING_PAYMENT_METHODS:
      return {
        ...state,
        loadingPaymentMethods: true,
        paymentMethods: null,
        paymentMethodsErrorMessage: null,
      };

    case ADD_PAYMENT_METHODS:
      return {
        ...state,
        loadingPaymentMethods: false,
        paymentMethods: action.paymentMethods,
        paymentMethodsErrorMessage: null,
      };

    case FETCH_PAYMENT_METHODS_FAILED:
      return {
        ...state,
        loadingPaymentMethods: false,
        paymentMethods: null,
        paymentMethodsErrorMessage: action.error,
      };

    case CREATING_PAYMENT_METHOD:
      return {
        ...state,
        creatingPaymentMethod: true,
        createPaymentMethodSuccess: false,
        createPaymentMethodErrorMessage: null,
      };

    case CREATE_PAYMENT_METHOD_SUCCESS:
      const newPaymentMethods = state.paymentMethods ? [...state.paymentMethods] : [];
      newPaymentMethods.push(action.paymentMethod);

      return {
        ...state,
        paymentMethods: newPaymentMethods,
        creatingPaymentMethod: false,
        createPaymentMethodSuccess: true,
        createPaymentMethodErrorMessage: null,
      };

    case CREATE_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        creatingPaymentMethod: false,
        createPaymentMethodSuccess: false,
        createPaymentMethodErrorMessage: action.error,
      };

    case UPDATING_PAYMENT_METHOD:
      return {
        ...state,
        updatingPaymentMethod: true,
        updatePaymentMethodSuccess: false,
        updatePaymentMethodErrorMessage: null,
      };

    case UPDATE_PAYMENT_METHOD_SUCCESS:
      let updatedPaymentMethod = state.paymentMethods!.map((c) => {
        if (c.id === action.paymentMethod.id) {
          let returnedPaymentMethod: PaymentMethod = {
            ...c,
            values: action.paymentMethod.values,
          };

          return returnedPaymentMethod;
        }

        return c;
      });

      return {
        ...state,
        updatingPaymentMethod: false,
        updatePaymentMethodSuccess: true,
        paymentMethods: updatedPaymentMethod,
        updatePaymentMethodErrorMessage: null,
      };

    case UPDATE_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        updatingPaymentMethod: false,
        updatePaymentMethodSuccess: false,
        updatePaymentMethodErrorMessage: action.error,
      };

    case DELETING_PAYMENT_METHOD:
      return {
        ...state,
        deletingPaymentMethod: true,
        deletePaymentMethodSuccess: false,
        deletePaymentMethodErrorMessage: null,
      };

    case DELETE_PAYMENT_METHOD_SUCCESS:
      const nonDeletedPaymentMethods =
        state.paymentMethods && state.paymentMethods.filter((c) => c.issuerId !== action.paymentMethodId);

      return {
        ...state,
        deletingPaymentMethod: false,
        deletePaymentMethodSuccess: true,
        paymentMethods: nonDeletedPaymentMethods,
        deletePaymentMethodErrorMessage: null,
      };

    case DELETE_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        deletingPaymentMethod: false,
        deletePaymentMethodSuccess: false,
        deletePaymentMethodErrorMessage: action.error,
      };

    case FETCHING_ISSUERS:
      return {
        ...state,
        loadingIssuers: true,
        issuers: null,
        issuersErrorMessage: null,
      };

    case ADD_ISSUERS:
      return {
        ...state,
        loadingIssuers: false,
        issuers: action.issuers,
        issuersErrorMessage: null,
      };

    case FETCHING_BUSINESS_ISSUERS:
      return {
        ...state,
        loadingBusinessIssuers: true,
        businessIssuers: null,
        businessIssuersErrorMessage: null,
      };

    case ADD_BUSINESS_ISSUERS:
      return {
        ...state,
        loadingBusinessIssuers: false,
        businessIssuers: action.issuers,
        businessIssuersErrorMessage: null,
      };

    case FETCH_BUSINESS_ISSUERS_FAILED:
      return {
        ...state,
        loadingBusinessIssuers: false,
        businessIssuers: null,
        businessIssuersErrorMessage: action.error,
      };

    case FETCH_ISSUERS_FAILED:
      return {
        ...state,
        loadingIssuers: false,
        issuers: null,
        issuersErrorMessage: action.error,
      };

    case SENDING_DESIRED_ISSUERS:
      return {
        ...state,
        sendingDesiredIssuers: true,
        desiredIssuersSuccess: false,
        desiredIssuersErrorMessage: null,
      };

    case DESIRED_ISSUERS_SUCCESS:
      return {
        ...state,
        sendingDesiredIssuers: false,
        desiredIssuersSuccess: true,
        desiredIssuersErrorMessage: null,
      };

    case DESIRED_ISSUERS_FAILED:
      return {
        ...state,
        sendingDesiredIssuers: false,
        desiredIssuersSuccess: false,
        desiredIssuersErrorMessage: action.error,
      };

    case FETCHING_INSTALLMENTS:
      return {
        ...state,
        possibleInstallments: null,
        fetchingPossibleInstallments: true,
        fetchInstallmentsError: null,
      };

    case FETCH_INSTALLMENTS_SUCCESS:
      return {
        ...state,
        possibleInstallments: action.installments,
        fetchingPossibleInstallments: false,
        fetchInstallmentsError: null,
      };

    case FETCH_INSTALLMENTS_FAILED:
      return {
        ...state,
        possibleInstallments: null,
        fetchingPossibleInstallments: false,
        fetchInstallmentsError: action.error,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
