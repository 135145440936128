import { Dispatch } from 'redux';
import { paymentMethodsService } from '../../services/paymentMethods.service';
import {
  ADD_BUSINESS_ISSUERS,
  ADD_ISSUERS,
  ADD_PAYMENT_METHODS,
  CREATE_BUSINESS_PAYMENT_METHOD_FAILED,
  CREATE_BUSINESS_PAYMENT_METHOD_SUCCESS,
  CREATE_PAYMENT_METHOD_FAILED,
  CREATE_PAYMENT_METHOD_SUCCESS,
  CreateBusinessPaymentMethodTypes,
  CreatePaymentMethodTypes,
  CREATING_BUSINESS_PAYMENT_METHOD,
  CREATING_PAYMENT_METHOD,
  DELETE_BUSINESS_PAYMENT_METHOD_FAILED,
  DELETE_BUSINESS_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_FAILED,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DeleteBusinessPaymentMethodTypes,
  DeletePaymentMethodTypes,
  DELETING_BUSINESS_PAYMENT_METHOD,
  DELETING_PAYMENT_METHOD,
  DESIRED_ISSUERS_FAILED,
  DESIRED_ISSUERS_SUCCESS,
  DesiredIssuersTypes,
  FETCH_BUSINESS_ISSUERS_FAILED,
  FETCH_INSTALLMENTS_FAILED,
  FETCH_INSTALLMENTS_SUCCESS,
  FETCH_ISSUERS_FAILED,
  FETCH_PAYMENT_METHODS_FAILED,
  FetchBusinessIssuersTypes,
  FETCHING_BUSINESS_ISSUERS,
  FETCHING_INSTALLMENTS,
  FETCHING_ISSUERS,
  FETCHING_PAYMENT_METHODS,
  FetchIssuersTypes,
  FetchPaymentMethodsTypes,
  InstallmentsTypes,
  SENDING_DESIRED_ISSUERS,
  UPDATE_BUSINESS_PAYMENT_METHOD_FAILED,
  UPDATE_BUSINESS_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAILED,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UpdateBusinessPaymentMethodTypes,
  UpdatePaymentMethodTypes,
  UPDATING_BUSINESS_PAYMENT_METHOD,
  UPDATING_PAYMENT_METHOD,
} from '../config/ActionTypes';
import {
  BusinessIssuer,
  CreatePaymentMethodRequest,
  DesiredIssuersRequest,
  Issuer,
  PaymentMethod,
  UpdatePaymentMethodRequest,
} from '../config/types';

//#region Fetch payment methods

export const fetchPaymentMethods = (businessId: number) => {
  return (dispatch: Dispatch<FetchPaymentMethodsTypes>) => {
    dispatch(loadingPaymentMethods());

    return paymentMethodsService.fetchPaymentMethods(businessId).then(
      (response) => {
        dispatch(addPaymentMethods(response!));
      },
      (error) => {
        dispatch(paymentMethodsFailed(error));
      },
    );
  };
};

export const loadingPaymentMethods = (): FetchPaymentMethodsTypes => ({
  type: FETCHING_PAYMENT_METHODS,
});

export const addPaymentMethods = (paymentMethods: PaymentMethod[]): FetchPaymentMethodsTypes => ({
  type: ADD_PAYMENT_METHODS,
  paymentMethods: paymentMethods,
});

export const paymentMethodsFailed = (error: string): FetchPaymentMethodsTypes => ({
  type: FETCH_PAYMENT_METHODS_FAILED,
  error,
});

//#endregion

//#region Create payment method

export const createPaymentMethod = (createPaymentMethodRequest: CreatePaymentMethodRequest) => {
  return (dispatch: Dispatch<CreatePaymentMethodTypes>) => {
    dispatch(creatingPaymentMethod());

    return paymentMethodsService.createPaymentMethod(createPaymentMethodRequest).then(
      (response) => {
        dispatch(createPaymentMethodSuccess(response!));
      },
      (error) => {
        dispatch(createPaymentMethodFailed(error));
      },
    );
  };
};

export const creatingPaymentMethod = (): CreatePaymentMethodTypes => ({
  type: CREATING_PAYMENT_METHOD,
});

export const createPaymentMethodSuccess = (paymentMethod: PaymentMethod): CreatePaymentMethodTypes => ({
  type: CREATE_PAYMENT_METHOD_SUCCESS,
  paymentMethod,
});

export const createPaymentMethodFailed = (error: string): CreatePaymentMethodTypes => ({
  type: CREATE_PAYMENT_METHOD_FAILED,
  error,
});
//#endregion

//#region createBusinessPaymentMethod
export const createBusinessPaymentMethod = (
  createPaymentMethodRequest: CreatePaymentMethodRequest,
  businessId: number,
) => {
  return (dispatch: Dispatch<CreateBusinessPaymentMethodTypes | CreatePaymentMethodTypes>) => {
    dispatch(creatingBusinessPaymentMethod());

    return paymentMethodsService.createPaymentMethod(createPaymentMethodRequest).then(
      (response) => {
        dispatch(createBusinessPaymentMethodSuccess(response!, businessId));
      },
      (error) => {
        dispatch(createBusinessPaymentMethodFailed(error));
      },
    );
  };
};

export const creatingBusinessPaymentMethod = (): CreateBusinessPaymentMethodTypes => ({
  type: CREATING_BUSINESS_PAYMENT_METHOD,
});

export const createBusinessPaymentMethodSuccess = (
  paymentMethod: PaymentMethod,
  businessId: number,
): CreateBusinessPaymentMethodTypes => ({
  type: CREATE_BUSINESS_PAYMENT_METHOD_SUCCESS,
  paymentMethod,
  businessId,
});

export const createBusinessPaymentMethodFailed = (error: string): CreateBusinessPaymentMethodTypes => ({
  type: CREATE_BUSINESS_PAYMENT_METHOD_FAILED,
  error,
});

//#endregion

//#region Update payment method

export const updatePaymentMethod = (updatePaymentMethodRequest: UpdatePaymentMethodRequest) => {
  return (dispatch: Dispatch<UpdatePaymentMethodTypes>) => {
    dispatch(updatingPaymentMethod());

    return paymentMethodsService.updatePaymentMethod(updatePaymentMethodRequest).then(
      (response) => {
        dispatch(updatePaymentMethodSuccess(response!));
      },
      (error) => {
        dispatch(updatePaymentMethodFailed(error));
      },
    );
  };
};

export const updatingPaymentMethod = (): UpdatePaymentMethodTypes => ({
  type: UPDATING_PAYMENT_METHOD,
});

export const updatePaymentMethodSuccess = (paymentMethod: PaymentMethod): UpdatePaymentMethodTypes => ({
  type: UPDATE_PAYMENT_METHOD_SUCCESS,
  paymentMethod,
});

export const updatePaymentMethodFailed = (error: string): UpdatePaymentMethodTypes => ({
  type: UPDATE_PAYMENT_METHOD_FAILED,
  error,
});

//#endregion

//#region  updateBusinessPaymentMethod

export const updateBusinessPaymentMethod = (
  updatePaymentMethodRequest: UpdatePaymentMethodRequest,
  businessId: number,
) => {
  return (dispatch: Dispatch<UpdateBusinessPaymentMethodTypes>) => {
    dispatch(updatingBusinessPaymentMethod());
    return paymentMethodsService.updatePaymentMethod(updatePaymentMethodRequest).then(
      (response) => {
        dispatch(updateBusinessPaymentMethodSuccess(response!, businessId));
      },
      (error) => {
        dispatch(updateBusinessPaymentMethodFailed(error));
      },
    );
  };
};

export const updatingBusinessPaymentMethod = (): UpdateBusinessPaymentMethodTypes => ({
  type: UPDATING_BUSINESS_PAYMENT_METHOD,
});

export const updateBusinessPaymentMethodSuccess = (
  paymentMethod: PaymentMethod,
  businessId: number,
): UpdateBusinessPaymentMethodTypes => ({
  type: UPDATE_BUSINESS_PAYMENT_METHOD_SUCCESS,
  paymentMethod,
  businessId,
});

export const updateBusinessPaymentMethodFailed = (error: string): UpdateBusinessPaymentMethodTypes => ({
  type: UPDATE_BUSINESS_PAYMENT_METHOD_FAILED,
  error,
});

//#endregion

//#region Delete payment method

export const deletePaymentMethod = (businessId: number, issuerId: number, isLast: boolean) => {
  return (dispatch: Dispatch<DeletePaymentMethodTypes>) => {
    dispatch(deletingPaymentMethod());

    return paymentMethodsService.deletePaymentMethod(businessId, issuerId).then(
      (response) => {
        dispatch(deletePaymentMethodSuccess(issuerId, isLast));
      },
      (error) => {
        dispatch(deletePaymentMethodFailed(error));
      },
    );
  };
};

export const deletingPaymentMethod = (): DeletePaymentMethodTypes => ({
  type: DELETING_PAYMENT_METHOD,
});

export const deletePaymentMethodSuccess = (
  paymentMethodId: number,
  isLast: boolean,
): DeletePaymentMethodTypes => ({
  type: DELETE_PAYMENT_METHOD_SUCCESS,
  paymentMethodId,
  isLast,
});

export const deletePaymentMethodFailed = (error: string): DeletePaymentMethodTypes => ({
  type: DELETE_PAYMENT_METHOD_FAILED,
  error,
});

export const deleteBusinessPaymentMethod = (businessId: number, paymentMethodId: number) => {
  return (dispatch: Dispatch<DeleteBusinessPaymentMethodTypes>) => {
    dispatch(deletingBusinessPaymentMethod());

    return paymentMethodsService.deletePaymentMethod(businessId, paymentMethodId).then(
      (response) => {
        dispatch(deleteBusinessPaymentMethodSuccess(paymentMethodId, businessId));
      },
      (error) => {
        dispatch(deleteBusinessPaymentMethodFailed(error));
      },
    );
  };
};

export const deletingBusinessPaymentMethod = (): DeleteBusinessPaymentMethodTypes => ({
  type: DELETING_BUSINESS_PAYMENT_METHOD,
});

export const deleteBusinessPaymentMethodSuccess = (
  paymentMethodId: number,
  businessId: number,
): DeleteBusinessPaymentMethodTypes => ({
  type: DELETE_BUSINESS_PAYMENT_METHOD_SUCCESS,
  paymentMethodId,
  businessId,
});

export const deleteBusinessPaymentMethodFailed = (error: string): DeleteBusinessPaymentMethodTypes => ({
  type: DELETE_BUSINESS_PAYMENT_METHOD_FAILED,
  error,
});

//#endregion

//#region Fetch issuers

export const fetchIssuers = () => {
  return (dispatch: Dispatch<FetchIssuersTypes>) => {
    dispatch(loadingIssuers());

    return paymentMethodsService.fetchIssuers().then(
      (response) => {
        dispatch(addIssuers(response!));
      },
      (error) => {
        dispatch(issuersFailed(error));
      },
    );
  };
};

export const loadingIssuers = (): FetchIssuersTypes => ({
  type: FETCHING_ISSUERS,
});

export const addIssuers = (issuers: Issuer[]): FetchIssuersTypes => ({
  type: ADD_ISSUERS,
  issuers,
});

export const issuersFailed = (error: string): FetchIssuersTypes => ({
  type: FETCH_ISSUERS_FAILED,
  error,
});

//#endregion

//#region Fetch business' issuers

export const fetchBusinessIssuers = (businessId: number) => {
  return (dispatch: Dispatch<FetchBusinessIssuersTypes>) => {
    dispatch(loadingBusinessIssuers());

    return paymentMethodsService.fetchBusinessIssuers(businessId).then(
      (response) => {
        dispatch(addBusinessIssuers(response!));
      },
      (error) => {
        dispatch(issuersBusinessFailed(error));
      },
    );
  };
};

export const loadingBusinessIssuers = (): FetchBusinessIssuersTypes => ({
  type: FETCHING_BUSINESS_ISSUERS,
});

export const addBusinessIssuers = (issuers: BusinessIssuer[]): FetchBusinessIssuersTypes => ({
  type: ADD_BUSINESS_ISSUERS,
  issuers,
});

export const issuersBusinessFailed = (error: string): FetchBusinessIssuersTypes => ({
  type: FETCH_BUSINESS_ISSUERS_FAILED,
  error,
});

//#endregion

//#region Send desired issuers

export const sendDesiredIssuers = (businessId: number, issuers: DesiredIssuersRequest) => {
  return (dispatch: Dispatch<DesiredIssuersTypes>) => {
    dispatch(sendingDesiredIssuers());

    return paymentMethodsService.sendDesiredIssuers(businessId, issuers).then(
      (response) => {
        dispatch(desiredIssuersSuccess());
      },
      (error) => {
        dispatch(desiredIssuersFailed(error));
      },
    );
  };
};

export const sendingDesiredIssuers = (): DesiredIssuersTypes => ({
  type: SENDING_DESIRED_ISSUERS,
});

export const desiredIssuersSuccess = (): DesiredIssuersTypes => ({
  type: DESIRED_ISSUERS_SUCCESS,
});

export const desiredIssuersFailed = (error: string): DesiredIssuersTypes => ({
  type: DESIRED_ISSUERS_FAILED,
  error,
});

//#endregion

//#region fetch installments

export const fetchInstallments = () => {
  return (dispatch: Dispatch<InstallmentsTypes>) => {
    dispatch(fetchingInstallments());

    return paymentMethodsService.fetchPossibleInstallments().then(
      (response) => {
        dispatch(fetchingInstallmentsSuccess(response!));
      },
      (error) => {
        dispatch(fetchInstallmentsFailed(error));
      },
    );
  };
};

export const fetchingInstallments = (): InstallmentsTypes => ({
  type: FETCHING_INSTALLMENTS,
});

export const fetchingInstallmentsSuccess = (installments: number[]): InstallmentsTypes => ({
  type: FETCH_INSTALLMENTS_SUCCESS,
  installments,
});

export const fetchInstallmentsFailed = (error: string): InstallmentsTypes => ({
  type: FETCH_INSTALLMENTS_FAILED,
  error,
});

//#endregion
