import { createStyles, Divider, makeStyles, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '../../assets/addIcon';
import DeleteIcon from '../../assets/deleteIcon';
import { Client, PaymentMethod, RootState } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { CustomSwitch } from '../CustomSwitch';
import SinglePaymentMethodDeleteTooltip from '../PaymentMethods/SingleDeletePaymentMethodDeleteTooltip';

const useStyles = makeStyles(() =>
  createStyles({
    settingsContainer: {
      padding: '1.3125rem 2rem',
      marginBottom: '1.875rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: styles.white,
      boxShadow: styles.boxShadow,
      borderRadius: '4px',
      color: styles.slateBlue,
      '& span': {
        margin: 0,
      },
    },
  }),
);

interface ClientSettingProps {
  selectedClient: Client;
  handleBillingChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  createPaymentMethod: () => void;
  updatePaymentMethod: (paymentMethod: PaymentMethod) => void;
  beginDeletePaymentMethod: (commerce: PaymentMethod, isLast: boolean) => void;
}

function ClientSettings({
  selectedClient,
  handleBillingChange,
  createPaymentMethod,
  updatePaymentMethod,
  beginDeletePaymentMethod,
}: ClientSettingProps) {
  const classes = useStyles();
  const paymentMethods: PaymentMethod[] | null = useSelector(
    (state: RootState) => state.paymentMethods.paymentMethods,
  );

  return (
    <>
      <div className={classes.settingsContainer}>
        <Typography>Habilitar facturación electrónica</Typography>
        <CustomSwitch
          checked={selectedClient!.billingEnabled}
          onChange={handleBillingChange}
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>
      <Typography variant="h6">Medios de Pago</Typography>
      <div className="payment-methods-list">
        <div className="new-payment-method" onClick={createPaymentMethod}>
          <AddIcon />
          <Typography variant="subtitle2">Nuevo medio de pago</Typography>
        </div>
        {paymentMethods &&
          paymentMethods.map((paymentMethod, index) => {
            return (
              <div className="payment-method" onClick={() => updatePaymentMethod(paymentMethod)} key={index}>
                <div className="trash">
                  {paymentMethods && paymentMethods.length === 1 ? (
                    <SinglePaymentMethodDeleteTooltip />
                  ) : (
                    <Tooltip title="Eliminar" placement="top" enterDelay={500}>
                      <span
                        onClick={(e: React.SyntheticEvent<any>) => {
                          e.stopPropagation();
                          beginDeletePaymentMethod(paymentMethod, paymentMethods.length === 1);
                        }}
                      >
                        <DeleteIcon />
                      </span>
                    </Tooltip>
                  )}
                </div>
                <img src={paymentMethod.issuerImageUrl} alt="logo" />
                <Divider orientation="horizontal" className="divider" />
                <div className="number">
                  <Typography noWrap className="payment-method-text">
                    {paymentMethod.issuerName}
                  </Typography>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default ClientSettings;
