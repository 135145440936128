import {
  CreatePaymentMethodForSubBusinessRequest,
  CreateSubBusinessRequest,
  PaymentMethod,
  SubBusiness,
  UpdateSubBusinessRequest,
} from '../store/config/types';
import { get, httpDelete, post, put } from './base.service';
import { endpoints } from './endpoints';

export const subBusinessService = {
  fetchSubBusiness,
  createSubBusiness,
  updateSubBusiness,
  deleteSubBusinessPaymentMethod,
  createPaymentMethodForSubBusiness,
};

async function fetchSubBusiness(businessId: number): Promise<SubBusiness[] | undefined> {
  return await get<SubBusiness[]>(`${endpoints['subBusiness']}?businessId=${businessId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function createSubBusiness(
  createSubBusinessRequest: CreateSubBusinessRequest,
): Promise<SubBusiness | undefined> {
  return await post<SubBusiness>(`${endpoints['subBusiness']}`, createSubBusinessRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function updateSubBusiness(
  updateSubBusinessRequest: UpdateSubBusinessRequest,
): Promise<SubBusiness | undefined> {
  return await put<SubBusiness>(`${endpoints['subBusiness']}`, updateSubBusinessRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function deleteSubBusinessPaymentMethod(
  businessId: number,
  paymentMethodId: number,
  subBusinessId: string,
): Promise<SubBusiness | undefined> {
  return await httpDelete<SubBusiness>(
    `${endpoints['issuers']}/configuration/${businessId}/${paymentMethodId}`,
    { subBusinessId },
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function createPaymentMethodForSubBusiness(
  createPaymentMethodForSubBusinessRequest: CreatePaymentMethodForSubBusinessRequest,
): Promise<PaymentMethod | undefined> {
  return await post<PaymentMethod>(
    `${endpoints['issuers']}/configuration`,
    createPaymentMethodForSubBusinessRequest,
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}
