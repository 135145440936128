import { useDispatch, useSelector } from 'react-redux';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { resetUpdateSubBusinessPaymentMethodState } from '../../store/action_creators/subBusiness.actions';
import { Status } from '../../store/config/enums';
import { RootState } from '../../store/config/types';

interface PaymentMethodSnackbarProps {
  updatingSubBusinessPaymentMethod: boolean;
  setUpdatingSubBusinessPaymentMethod: (value: boolean) => void;
}

function PaymentMethodSnackbar({
  updatingSubBusinessPaymentMethod,
  setUpdatingSubBusinessPaymentMethod,
}: PaymentMethodSnackbarProps) {
  const dispatch = useDispatch();
  const subBusiness = useSelector((state: RootState) => state.subBusiness);

  const isSuccessful =
    subBusiness.addSubBusinessPaymentMethodSuccess || subBusiness.deleteSubBusinessPaymentMethodSuccess;
  const isError =
    subBusiness.addSubBusinessPaymentMethodErrorMessage ||
    subBusiness.deleteSubBusinessPaymentMethodErrorMessage;

  const message = isSuccessful
    ? `Medio de pago ${
        subBusiness.addSubBusinessPaymentMethodSuccess ? 'agregado' : 'eliminado'
      } correctamente`
    : isError
    ? `Ocurrió un error al ${
        subBusiness.addSubBusinessPaymentMethodErrorMessage ? 'agregar' : 'eliminar'
      } el medio de pago`
    : 'Ocurrió un error al actualizar el medio de pago';

  const handleUpdatePaymentSnackbarExited = () => {
    dispatch(resetUpdateSubBusinessPaymentMethodState());
  };

  const handleClose = () => {
    setUpdatingSubBusinessPaymentMethod(false);
  };

  return (
    <CustomSnackbar
      open={
        updatingSubBusinessPaymentMethod &&
        (subBusiness.addSubBusinessPaymentMethodSuccess ||
          subBusiness.addSubBusinessPaymentMethodErrorMessage !== null ||
          subBusiness.deleteSubBusinessPaymentMethodSuccess ||
          subBusiness.deleteSubBusinessErrorMessage !== null)
      }
      message={message}
      handleClose={handleClose}
      type={isSuccessful ? Status.SUCCESS : Status.ERROR}
      onExited={handleUpdatePaymentSnackbarExited}
    />
  );
}

export default PaymentMethodSnackbar;
