import { UserTypeEnum } from '../../store/config/enums';
import { Clients } from '../Icons/Clients';
import Dashboard from '../Icons/Dashboard/Dashboard';
import { Payments } from '../Icons/Payments';
import { PermanentLink } from '../Icons/PermanentLink';
import { SingleLink } from '../Icons/SingleLink';

export const options = [
  {
    name: 'Tablero',
    icon: <Dashboard className="icon" />,
    route: '/dashboard',
    value: 0,
    roles: [UserTypeEnum.OWNER, UserTypeEnum.COLLABORATOR],
  },
  {
    name: 'Links multiuso',
    icon: <PermanentLink className="icon" />,
    route: '/links',
    secondaryRoute: '/permanent-link',
    value: 1,
    roles: [UserTypeEnum.OWNER, UserTypeEnum.COLLABORATOR],
  },
  {
    name: 'Links de 1 uso',
    icon: <SingleLink className="icon" />,
    route: '/one-use-links',
    secondaryRoute: '/one-use-link',
    value: 2,
    roles: [UserTypeEnum.OWNER, UserTypeEnum.COLLABORATOR],
  },
  {
    name: 'Pagos',
    icon: <Payments className="icon" />,
    route: '/payments',
    value: 3,
    roles: [UserTypeEnum.SUPERADMIN, UserTypeEnum.OWNER, UserTypeEnum.COLLABORATOR],
  },
  {
    name: 'Clientes',
    icon: <Clients className="icon" />,
    route: '/manage-clients',
    value: 5,
    roles: [UserTypeEnum.SUPERADMIN],
  },
  {
    name: 'Verificaciones',
    icon: <Clients className="icon" />,
    route: '/verifications',
    value: 6,
    roles: [UserTypeEnum.SUPERADMIN],
  },
  {
    name: 'Hidden',
    icon: <Payments className="icon" />,
    route: '/hidden',
    value: 99,
    roles: [UserTypeEnum.SUPERADMIN, UserTypeEnum.OWNER, UserTypeEnum.COLLABORATOR],
  },
];

export const settingsOptions = [
  {
    name: 'Empresa',
    route: '/business',
    value: 1,
    roles: [UserTypeEnum.OWNER],
  },
  {
    name: 'Configuración',
    route: '/configuration',
    value: 2,
    roles: [UserTypeEnum.OWNER],
  },
  {
    name: 'Colaboradores',
    route: '/users',
    value: 3,
    roles: [UserTypeEnum.OWNER],
  },
];

export const getOptionsFromRole = (role?: UserTypeEnum) => {
  return options.filter((o) => {
    if (role && o.roles?.includes(role)) {
      return o;
    }
    return null;
  });
};

export const getActiveTabIndex = (path: string, fromRoute: string) => {
  if (path.includes('/payment')) {
    return fromRoute === '/dashboard' ? 0 : 3;
  }
  return options.findIndex((o) => o.route === path);
};
