import { IconButton, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Field, useField } from 'formik';
import { useState } from 'react';
import EyeIcon from '../Icons/EyeIcon';
import EyeSlashIcon from '../Icons/EyeSlashIcon';
import { FormTextField } from '../forms/FormTextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    passwordField: {
      position: 'relative',
    },
    eyeButton: {
      padding: '0.5rem',
      position: 'absolute',
      right: '0.625rem',
      top: '60%',
      transform: 'translateY(-50%)',
      '&:hover': {
        borderRadius: '50%',
      },
    },
    eyeButtonError: {
      padding: '0.5rem',
      position: 'absolute',
      right: '2.25rem',
      top: '44%',
      transform: 'translateY(-50%)',
      '&:hover': {
        borderRadius: '50%',
      },
      [theme.breakpoints.down('xs')]: {
        top: '36%',
      },
    },
  }),
);

interface PasswordFieldProps {
  inputName: string;
  placeholder?: string;
  inputStyles?: string;
  buttonStyles?: string;
}

function PasswordField({
  inputName = 'password',
  placeholder,
  inputStyles,
  buttonStyles,
}: PasswordFieldProps) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [field, meta] = useField(inputName);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const { value, touched, error } = meta;

  return (
    <div className={classes.passwordField}>
      <Field
        className={inputStyles || 'form-row'}
        name={inputName}
        component={FormTextField}
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder || 'Contraseña'}
        value={value}
        touched={touched}
      />
      <IconButton
        className={`${error && touched ? classes.eyeButtonError : classes.eyeButton} ${buttonStyles}`}
        onClick={togglePasswordVisibility}
      >
        {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
      </IconButton>
    </div>
  );
}

export default PasswordField;
