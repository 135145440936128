import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core';
import colors from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '1.5625rem',
      [theme.breakpoints.down('sm')]: {
        '& .MuiDialog-paper': {
          margin: '0',
          width: '100%',
        },
      },
      '& .MuiDialogActions-root': {
        justifyContent: 'center',
        paddingBottom: '1.5625rem',
      },
    },
    title: {
      padding: '1.5625rem 1.875rem 0.3125rem',
      textAlign: 'center',
    },
    contentText: {
      textAlign: 'center',
      fontSize: '0.9375rem',
      color: colors.slateBlue,
    },
  }),
);

interface DisableSplitPaymentsDialogProps {
  open: boolean;
  handleClose(): void;
  handleConfirm(): void;
}

function DisableSplitPaymentsDialog({ open, handleClose, handleConfirm }: DisableSplitPaymentsDialogProps) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs" className={classes.container}>
      <DialogTitle className={classes.title}>
        ¿Estás seguro que deseas deshabilitar los split-payments?
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>
          Si deshabilitas los split-payments, todos los links creados bajo esta modalidad quedarán inactivos,
          y los clientes no podrán usarlos para pagar.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Cancelar
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained" autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DisableSplitPaymentsDialog;
