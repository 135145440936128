import {
  ADD_CLIENTS,
  ClientDataTypes,
  CREATE_BUSINESS_PAYMENT_METHOD_FAILED,
  CREATE_BUSINESS_PAYMENT_METHOD_SUCCESS,
  CREATING_BUSINESS_PAYMENT_METHOD,
  DELETE_BUSINESS_PAYMENT_METHOD_FAILED,
  DELETE_BUSINESS_PAYMENT_METHOD_SUCCESS,
  DELETING_BUSINESS_PAYMENT_METHOD,
  FETCH_CLIENTS_FAILED,
  FETCHING_CLIENTS,
  LogoutActionTypes,
  PaymentMethodsDataTypes,
  TOGGLE_CLIENT_BILLING_FAILED,
  TOGGLE_CLIENT_BILLING_SUCCESS,
  TOGGLING_CLIENT_BILLING,
  UPDATE_BUSINESS_PAYMENT_METHOD_SUCCESS,
  UPDATING_BUSINESS_PAYMENT_METHOD,
} from '../config/ActionTypes';
import { ClientsState } from '../config/types';

export const initialState: ClientsState = {
  loadingClients: false,
  clients: null,
  loadingClientsErrorMessage: null,
  updatingBilling: false,
  billingUpdateSuccess: false,
  billingUpdateError: null,
  creatingPaymentMethods: false,
  creatingPaymentMethodsSuccess: false,
  creatingPaymentMethodsErrorMessage: null,
  updatingPaymentMethods: false,
  updatingPaymentMethodsSuccess: false,
  updatingPaymentMethodsErrorMessage: null,
  deletingPaymentMethods: false,
  deletingPaymentMethodsSuccess: false,
  deletingPaymentMethodsErrorMessage: null,
};

export function clientsReducer(
  state = initialState,
  action: ClientDataTypes | LogoutActionTypes | PaymentMethodsDataTypes,
): ClientsState {
  switch (action.type) {
    case FETCHING_CLIENTS:
      return {
        ...state,
        loadingClients: true,
        clients: null,
        loadingClientsErrorMessage: null,
      };
    case ADD_CLIENTS:
      return {
        ...state,
        loadingClients: false,
        clients: action.clients,
        loadingClientsErrorMessage: null,
      };
    case FETCH_CLIENTS_FAILED:
      return {
        ...state,
        loadingClients: false,
        clients: null,
        loadingClientsErrorMessage: action.error,
      };
    case TOGGLING_CLIENT_BILLING:
      return {
        ...state,
        updatingBilling: true,
        billingUpdateSuccess: false,
        billingUpdateError: null,
      };
    case TOGGLE_CLIENT_BILLING_SUCCESS:
      const updatedClients = state.clients?.map((cli) => {
        if (cli.id === action.request.id) {
          const newCli = cli;
          cli.billingEnabled = action.request.enabled;
          return newCli;
        } else return cli;
      });
      return {
        ...state,
        updatingBilling: false,
        billingUpdateSuccess: true,
        billingUpdateError: null,
        clients: updatedClients ?? null,
      };
    case TOGGLE_CLIENT_BILLING_FAILED:
      return {
        ...state,
        updatingBilling: false,
        billingUpdateSuccess: false,
        billingUpdateError: action.error,
      };

    case CREATING_BUSINESS_PAYMENT_METHOD: {
      return {
        ...state,
        creatingPaymentMethods: true,
        creatingPaymentMethodsSuccess: false,
        creatingPaymentMethodsErrorMessage: null,
      };
    }

    case CREATE_BUSINESS_PAYMENT_METHOD_SUCCESS: {
      const clientsWithNewCommerce =
        state.clients &&
        state.clients.map((cli) => {
          if (cli.id === action.businessId) {
            cli.commerces
              ? cli.commerces.push(action.paymentMethod)
              : (cli.commerces = [action.paymentMethod]);
          }
          return cli;
        });
      return {
        ...state,
        creatingPaymentMethods: false,
        creatingPaymentMethodsSuccess: true,
        creatingPaymentMethodsErrorMessage: null,
        clients: clientsWithNewCommerce,
      };
    }

    case CREATE_BUSINESS_PAYMENT_METHOD_FAILED: {
      return {
        ...state,
        creatingPaymentMethods: false,
        creatingPaymentMethodsSuccess: false,
        creatingPaymentMethodsErrorMessage: action.error,
      };
    }

    case UPDATING_BUSINESS_PAYMENT_METHOD: {
      return {
        ...state,
        updatingPaymentMethods: true,
        updatingPaymentMethodsSuccess: false,
        updatingPaymentMethodsErrorMessage: null,
      };
    }

    case UPDATE_BUSINESS_PAYMENT_METHOD_SUCCESS: {
      const clientsWithUpdatedCommerce =
        state.clients &&
        state.clients.map((cli) => {
          if (cli.id === action.businessId) {
            const i = cli.commerces!.findIndex((co) => co.id === action.paymentMethod.id);
            cli.commerces![i] = action.paymentMethod;
          }
          return cli;
        });

      return {
        ...state,
        updatingPaymentMethods: false,
        updatingPaymentMethodsSuccess: true,
        updatingPaymentMethodsErrorMessage: null,
        clients: clientsWithUpdatedCommerce,
      };
    }

    case DELETING_BUSINESS_PAYMENT_METHOD: {
      return {
        ...state,
        deletingPaymentMethods: true,
        deletingPaymentMethodsSuccess: false,
        deletingPaymentMethodsErrorMessage: null,
      };
    }

    case DELETE_BUSINESS_PAYMENT_METHOD_SUCCESS: {
      const clientsWithDelCommerce =
        state.clients &&
        state.clients.map((cli) => {
          if (cli.id === action.businessId) {
            const filteredComms = cli.commerces!.filter((co) => co.id !== action.paymentMethodId);
            cli.commerces = filteredComms;
          }
          return cli;
        });
      return {
        ...state,
        deletingPaymentMethods: false,
        deletingPaymentMethodsSuccess: true,
        deletingPaymentMethodsErrorMessage: null,
        clients: clientsWithDelCommerce,
      };
    }

    case DELETE_BUSINESS_PAYMENT_METHOD_FAILED: {
      return {
        ...state,
        deletingPaymentMethods: false,
        deletingPaymentMethodsSuccess: false,
        deletingPaymentMethodsErrorMessage: action.error,
      };
    }

    default:
      return state;
  }
}
