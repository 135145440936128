import { Paper, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { LandingLayout } from '../../components/Landing';
import { Loading } from '../../components/Loading';
import { currencyHelper } from '../../helpers/currencyHelper';
import { linkHelper } from '../../helpers/linkHelper';
import { numberHelper } from '../../helpers/numberHelper';
import { RootState } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '1rem',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      background: styles.white,
      maxWidth: '38.5rem',
      boxShadow: styles.boxShadowDark,
    },
    flexRow: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '0.5rem',
      gap: '4rem',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '0.5rem',
      },
    },
    detailTitle: {
      fontSize: '1.125rem',
      color: styles.slateBlue,
      fontWeight: Number(styles.semiBoldTextWeight),
    },
    rowTitle: {
      fontSize: '0.875rem',
      color: styles.slateBlue,
      fontWeight: Number(styles.semiBoldTextWeight),
    },
    rowText: {
      fontSize: '0.875rem',
      textAlign: 'end',
    },
    iva: {
      marginLeft: '0.5rem',
      fontSize: '0.625rem',
      color: styles.slateBlue,
      fontWeight: Number(styles.normalTextWeight),
      fontStyle: 'italic',
    },
    amount: {
      fontSize: '1rem',
      fontWeight: Number(styles.titleTextWeight),
    },
  }),
);

function ValidatePayment() {
  const classes = useStyles();
  const links = useSelector((state: RootState) => state.links);

  if (!links.linkData) {
    return <Loading />;
  }

  const title = 'Verificación en curso';
  const subtitle = (
    <span>
      Realizamos una rápida verificación para garantizar un mejor proceso. <br /> Agradecemos tu paciencia
      mientras completamos esta revisión.
    </span>
  );
  const totalAmount = linkHelper.getTotalLinkAmount(links.linkData?.link);
  const content = (
    <Paper className={classes.paper} elevation={1}>
      <Typography className={classes.detailTitle}>Detalle</Typography>
      <div className={classes.flexRow}>
        <Typography className={classes.rowTitle}>Descripción</Typography>
        <Typography className={classes.rowText}>{links.linkData?.link.description}</Typography>
      </div>
      <div className={classes.flexRow}>
        <Typography className={classes.rowTitle}>
          Importe
          {links.linkData && links.linkData.link.vatRate > 0 && <span className={classes.iva}>IVA inc.</span>}
        </Typography>
        <Typography variant="body2" className={classes.amount}>
          <span>{currencyHelper.getCurrencyToken(links.linkData?.link.amount.currency!)}</span>
          {numberHelper.getNumberWithDots(totalAmount)}
        </Typography>
      </div>
    </Paper>
  );

  return <LandingLayout title={title} subtitle={subtitle} content={content} animation={<Loading />} />;
}

export default ValidatePayment;
