import { Paper } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { CustomSwitch } from '../../../components/CustomSwitch';
import { PaymentMethodCard } from '../../../components/PaymentMethodCard';
import { PaymentMethod, RootState } from '../../../store/config/types';
import { EmptyMessage } from '../../EmptyMessage';

interface PaymentMethodsProps {
  paymentMethods: PaymentMethod[];
  paymentMethodsIds: number[];
  setPaymentMethodsIds: React.Dispatch<React.SetStateAction<number[]>>;
  allInstallmentIds: number[];
  subBusinessId?: string | null;
  classes: Record<string, string>;
}

function PaymentMethods({
  paymentMethods,
  paymentMethodsIds,
  setPaymentMethodsIds,
  allInstallmentIds,
  subBusinessId,
  classes,
}: PaymentMethodsProps) {
  const subBusinesses = useSelector((state: RootState) => state.business.subBusinesses);
  const handleSwitchAllPaymentMethods = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethodsIds(event.target.checked ? allInstallmentIds : []);
  };
  const hasPaymentMethodsAvailable = paymentMethods.length > 0;
  const selectedSubBusinessName = subBusinesses?.find((sb) => sb.id === subBusinessId)?.name;

  return (
    <Paper className={classes.paperRoot}>
      <div className={classes.paymentHeader}>
        <p className={classes.contentTitleHeader}>Medios de Pago</p>
        {subBusinessId && hasPaymentMethodsAvailable && (
          <div className={classes.paymentSwitchContainer}>
            <p>Todos habilitados</p>
            <CustomSwitch
              onChange={handleSwitchAllPaymentMethods}
              checked={paymentMethodsIds.length === paymentMethods.length}
            />
          </div>
        )}
      </div>
      {!subBusinessId ? (
        <EmptyMessage
          title="Aún no tienes los destinos ingresados"
          message="Por favor selecciona los destinos para poder seleccionar los medios de pago"
        />
      ) : !hasPaymentMethodsAvailable ? (
        <EmptyMessage
          title="No hay medios de pago disponibles"
          message={`Por favor configura los medios de pago para el destino ${
            selectedSubBusinessName ?? 'seleccionado'
          }`}
        />
      ) : (
        <>
          <p>
            Elige los medios de pago con los que deseas que puedan realizarse los pagos a través de los links
          </p>
          {paymentMethods && paymentMethodsIds.length === 0 && (
            <p className={classes.error}>Debes seleccionar al menos un medio de pago</p>
          )}
          <div className={classes.paymentMethodContainer}>
            {paymentMethods.map((paymentMetod: PaymentMethod) => (
              <PaymentMethodCard
                key={paymentMetod.id}
                paymentMethod={paymentMetod}
                paymentMethodsIds={paymentMethodsIds}
                setPaymentMethodsIds={setPaymentMethodsIds}
              />
            ))}
          </div>
        </>
      )}
    </Paper>
  );
}

export default PaymentMethods;
