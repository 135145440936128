import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import { PaymentMethod } from '../../../store/config/types';
import { FormTextField } from '../FormTextField';

interface SoftDescriptorProps {
  paymentMethod?: PaymentMethod | null;
}

function SoftDescriptor({ paymentMethod }: SoftDescriptorProps) {
  return (
    <>
      <Grid item xs={12}>
        <Field
          name="softDescriptor"
          component={FormTextField}
          type="text"
          placeholder="Soft descriptor"
          disabled={!!paymentMethod}
        />
      </Grid>
      <Grid item xs={12} className="soft-descriptor">
        El "Soft descriptor" es el texto que se le muestra al cliente en el estado de cuenta de la tarjeta
      </Grid>
    </>
  );
}

export default SoftDescriptor;
