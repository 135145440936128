import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PaymentMethod, RootState, SplitLinkRequestValues } from '../../../store/config/types';

interface UpdatePaymentMethodsOptionsProps {
  setPaymentMethods: (paymentMethods: PaymentMethod[]) => void;
}

function UpdatePaymentMethodsOptions({ setPaymentMethods }: UpdatePaymentMethodsOptionsProps) {
  const { values } = useFormikContext<SplitLinkRequestValues>();
  const subBusinesses = useSelector((state: RootState) => state.business.subBusinesses);

  useEffect(() => {
    if (subBusinesses && subBusinesses.length > 0 && values.subBusinessId) {
      const selectedSubBusiness = subBusinesses.find(
        (subBusiness) => subBusiness.id === values.subBusinessId,
      );
      if (selectedSubBusiness) {
        setPaymentMethods(selectedSubBusiness.installmentsDto);
      } else {
        setPaymentMethods([]);
      }
    } else {
      setPaymentMethods([]);
    }
  }, [setPaymentMethods, subBusinesses, values.subBusinessId]);

  return null;
}

export default UpdatePaymentMethodsOptions;
