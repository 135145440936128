import { PaymentMethod, PaymentMethodsState } from '../store/config/types';

export const issuerHelper = {
  getIssuersList,
};

function getIssuersList(paymentMethods: PaymentMethodsState, paymentMethod: PaymentMethod | null) {
  return paymentMethods?.issuers?.map((i) => {
    const existsPaymentMethod =
      paymentMethods.paymentMethods &&
      paymentMethods.paymentMethods.filter((c) => c.issuerId && c.issuerId === Number(i.id)).length > 0;

    return {
      id: i.id,
      value: i.issuer,
      disabled: paymentMethod ? false : existsPaymentMethod,
      paymentProcessors: i.paymentProcessors,
    };
  });
}
